.accordeon-cnt {

}

.accordeon-item {
    @include transition(margin-bottom 0.2s);
    &.expanded {
        margin-bottom: 25px;
        a.accordeon-trigger {
            &:after {
            }
            &:hover {
                &:after {
                }
            }
            &:before {
                @include transform(rotateZ(180deg));
            }
        }
    }
    &.collapsed {
        margin-bottom: 22px;
        .accordeon-inner {
            height: 0 !important;
        }
    }
}

a.accordeon-trigger {
    display: block;
    @include ptsans-b;
    @include font(#333, 12px, 1);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    @include transition(color 0.2s);
    &:hover {
        color: $cYellow;
        text-decoration: none;
    }
    &:before {
        content:'f';
        position: absolute;
        right: 0;
        @include transition(transform 0.2s);
    }
 }

.accordeon-inner {
    overflow: hidden;
    @include transition(height 0.3s);
}

.accordeon-content {
    position: relative;
    @include box-sizing(border-box);
    padding-top: 16px;
}
