.no-csstransforms {
    .accordeon-item {
        &.expanded {

            a.accordeon-trigger {
                &:before {
                    content: 'e';
                }
            }
        }
    }
}

.lt-ie9 {
    .award-item {
        &:hover {
            .award-link {
                &:before {
                    display: none;
                }
                background: #fff;
                filter:alpha(opacity=0);
                opacity: 0;
            }
            .award-name {
                color: #f2f2f2;
            }
        }
    }
    .item-content-inner {
        right: -490px;
    }

    .show-info {
        .item-content-inner {
            right: 0;
        }
    }
    .item-zoom-cnt {
        &.show {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .item-photo {
        img {
            &:hover {
                cursor: pointer;
            }
        }
    }
}