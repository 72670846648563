/* ----------------- SIZES ---------------- */
$baseWidth: 1200px;
$headerHeight: 35px;
$footerHeight: 52px;
$sidebarWidth: 280px;
$sidebarGutter: 40px;
$contentWidth: $baseWidth - $sidebarGutter - $baseWidth;

/* ---------------- COLORS ---------------- */

$cBlack: #353636;
$cWhite: #fff;
$cLink: #de0f22;
$cLinkActive: #de0f22;
$cLinkVisited: #de717b;
$cYellow: #f5cb58;