/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 21, 2014 */


/* @font-face {
    font-family: 'avantgardegothiccbold';
    src: url('fonts/avantgardegothicc-bold.eot');
    src: url('fonts/avantgardegothicc-bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/avantgardegothicc-bold.woff') format('woff'),
    url('fonts/avantgardegothicc-bold.ttf') format('truetype'),
    url('fonts/avantgardegothicc-bold.svg#avantgardegothiccbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'avantgardegothiccregular';
    src: url('fonts/avantgardegothicc.eot');
    src: url('fonts/avantgardegothicc.eot?#iefix') format('embedded-opentype'),
    url('fonts/avantgardegothicc.woff') format('woff'),
    url('fonts/avantgardegothicc.ttf') format('truetype'),
    url('fonts/avantgardegothicc.svg#avantgardegothiccregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'amazing_groteskitalic';
    src: url('fonts/Amazing Grotesk Book Italic.eot');
    src: url('fonts/Amazing Grotesk Book Italic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Amazing Grotesk Book Italic.woff') format('woff'),
    url('fonts/Amazing Grotesk Book Italic.ttf') format('truetype'),
    url('fonts/Amazing Grotesk Book Italic.svg#amazing_groteskitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'amazing_groteskregular';
    src: url('fonts/Amazing Grotesk Book.eot');
    src: url('fonts/Amazing Grotesk Book.eot?#iefix') format('embedded-opentype'),
    url('fonts/Amazing Grotesk Book.woff') format('woff'),
    url('fonts/Amazing Grotesk Book.ttf') format('truetype'),
    url('fonts/Amazing Grotesk Book.svg#amazing_groteskregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'amazing_groteskdemibold';
    src: url('fonts/Amazing Grotesk Demi.eot');
    src: url('fonts/Amazing Grotesk Demi.eot?#iefix') format('embedded-opentype'),
    url('fonts/Amazing Grotesk Demi.woff') format('woff'),
    url('fonts/Amazing Grotesk Demi.ttf') format('truetype'),
    url('fonts/Amazing Grotesk Demi.svg#amazing_groteskdemibold') format('svg');
    font-weight: normal;
    font-style: normal;

} */

@font-face {
    font-family: 'als_rublregular';
    src: url('fonts/rouble.eot');
    src: url('fonts/rouble.eot?#iefix') format('embedded-opentype'),
         url('fonts/rouble.woff') format('woff'),
         url('fonts/rouble.ttf') format('truetype'),
         url('fonts/rouble.svg#als_rublregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Playfair Display';
    src: url('fonts/Playfair_Display/PlayfairDisplay-Regular.eot');
    src: url('fonts/Playfair_Display/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
         url('fonts/Playfair_Display/PlayfairDisplay-Regular.woff2') format('woff2'),
         url('fonts/Playfair_Display/PlayfairDisplay-Regular.woff') format('woff'),
         url('fonts/Playfair_Display/PlayfairDisplay-Regular.ttf')  format('truetype'),
         url('fonts/Playfair_Display/PlayfairDisplay-Regular.svg#Playfair Display') format('svg');
}
@font-face {
    font-family: 'Playfair Display Italic';
    src: url('fonts/Playfair_Display/PlayfairDisplay-Italic.eot');
    src: url('fonts/Playfair_Display/PlayfairDisplay-Italic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Playfair_Display/PlayfairDisplay-Italic.woff2') format('woff2'),
         url('fonts/Playfair_Display/PlayfairDisplay-Italic.woff') format('woff'),
         url('fonts/Playfair_Display/PlayfairDisplay-Italic.ttf')  format('truetype'),
         url('fonts/Playfair_Display/PlayfairDisplay-Italic.svg#Playfair Display') format('svg');
}
@font-face {
    font-family: 'Playfair Display';
    src: url('fonts/Playfair_Display/PlayfairDisplay-SemiBold.eot');
    src: url('fonts/Playfair_Display/PlayfairDisplay-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('fonts/Playfair_Display/PlayfairDisplay-SemiBold.woff2') format('woff2'),
         url('fonts/Playfair_Display/PlayfairDisplay-SemiBold.woff') format('woff'),
         url('fonts/Playfair_Display/PlayfairDisplay-SemiBold.ttf')  format('truetype'),
         url('fonts/Playfair_Display/PlayfairDisplay-SemiBold.svg#Playfair Display') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Playfair Display Bold';
    src: url('fonts/Playfair_Display/PlayfairDisplay-Bold.eot');
    src: url('fonts/Playfair_Display/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/Playfair_Display/PlayfairDisplay-Bold.woff2') format('woff2'),
         url('fonts/Playfair_Display/PlayfairDisplay-Bold.woff') format('woff'),
         url('fonts/Playfair_Display/PlayfairDisplay-Bold.ttf')  format('truetype'),
         url('fonts/Playfair_Display/PlayfairDisplay-Bold.svg#Playfair Display') format('svg');
}
@font-face {
    font-family: 'PT Sans';
    src: url('fonts/PT_Sans/PTSans-Regular.eot');
    src: url('fonts/PT_Sans/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('fonts/PT_Sans/PTSans-Regular.woff2') format('woff2'),
         url('fonts/PT_Sans/PTSans-Regular.woff') format('woff'),
         url('fonts/PT_Sans/PTSans-Regular.ttf')  format('truetype'),
         url('fonts/PT_Sans/PTSans-Regular.svg#PT Sans') format('svg');
}
@font-face {
    font-family: 'PT Sans Bold';
    src: url('fonts/PT_Sans/PTSans-Bold.eot');
    src: url('fonts/PT_Sans/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/PT_Sans/PTSans-Bold.woff2') format('woff2'),
         url('fonts/PT_Sans/PTSans-Bold.woff') format('woff'),
         url('fonts/PT_Sans/PTSans-Bold.ttf')  format('truetype'),
         url('fonts/PT_Sans/PTSans-Bold.svg#PT Sans') format('svg');
}
@font-face {
    font-family: 'PT Sans Italic';
    src: url('fonts/PT_Sans/PTSans-Italic.eot');
    src: url('fonts/PT_Sans/PTSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('fonts/PT_Sans/PTSans-Italic.woff2') format('woff2'),
         url('fonts/PT_Sans/PTSans-Italic.woff') format('woff'),
         url('fonts/PT_Sans/PTSans-Italic.ttf')  format('truetype'),
         url('fonts/PT_Sans/PTSans-Italic.svg#PT Sans') format('svg');
}

/* @mixin arialreg {
    font-family: Arial, sans-serif;
    font-weight: normal;
}

@mixin arialbold {
    font-family: Arial, sans-serif;
    font-weight: bold;
}

@mixin avreg {
    font-family: avantgardegothiccregular, Arial, sans-serif;
    font-weight: normal;
}

@mixin avbold {
    font-family: avantgardegothiccbold, Arial, sans-serif;
    font-weight: normal;
}

@mixin grotreg {
    font-family: amazing_groteskregular, Georgia, serif;
    font-weight: normal;
}

@mixin grotsemi {
    font-family: amazing_groteskdemibold, Georgia, serif;
    font-weight: normal;
}

@mixin grotit {
    font-family: amazing_groteskitalic, Georgia, serif;
    font-weight: normal;
} */

@mixin rub {
    font-family: als_rublregular;
}

@mixin play {
  font-family: 'Playfair Display', sans-serif;
  font-weight: normal;
}
@mixin play-i {
  font-family: 'Playfair Display Italic', sans-serif;
  font-weight: normal;
}
@mixin play-b {
  font-family: 'Playfair Display Bold', sans-serif;
  font-weight: normal;
}
@mixin ptsans {
  font-family: 'PT Sans', sans-serif;
}
@mixin ptsans-i {
  font-family: 'PT Sans Italic', sans-serif;
}
@mixin ptsans-b {
  font-family: 'PT Sans Bold', sans-serif;
}


@mixin font($color: #000, $size: 14px, $lh: inherit){
    font-size: $size;
    color: $color;
    line-height: $lh;
};


@font-face {
    font-family: "nmk";
    src:url("fonts/nmk.eot");
    src:url("fonts/nmk.eot?#iefix") format("embedded-opentype"),
    url("fonts/nmk.woff") format("woff"),
    url("fonts/nmk.ttf") format("truetype"),
    url("fonts/nmk.svg#nmk") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "nmk" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "nmk" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "x";
}
.icon-archive:before {
    content: "a";
}
.icon-hamburger:before {
    content: "b";
}
.icon-back:before {
    content: "c";
}
.icon-search:before {
    content: "d";
}
.icon-collapse:before {
    content: "e";
}
.icon-expand:before {
    content: "f";
}
.icon-check:before {
    content: "g";
}
.icon-edit:before {
    content: "h";
}
.icon-add:before {
    content: "i";
}
.icon-remove:before {
    content: "j";
}
.icon-next:before {
    content: "k";
}
.icon-prev:before {
    content: "l";
}
.icon-left-arrow:before {
    content: "m";
}
.icon-right-arrow:before {
    content: "n";
}
.icon-cal:before {
    content: "o";
}