.tooltipster-nmk {
    @include border-radius(2px);
    border: 1px solid #808080;
    background: #fff;
    @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.15));
}
.tooltipster-nmk .tooltipster-content {
    @include ptsans;
    @include font(#333, 12px, 18px);
    padding: 8px 12px;
}

.tooltipster-nmk .tooltipster-arrow-top span {
    bottom: -8px;
}
.tooltipster-nmk .tooltipster-arrow-top .tooltipster-arrow-border {
    bottom: -8px;
}

