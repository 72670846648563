#details-popup {

}

.select-coins-popup {
    .fancybox-default-skin {
        background: #fff;
    }
    .content-wrapper {
        padding-top: 20px;
    }
}

.detail-popup-header {
    @include play;
    @include font(#303030, 30px, 1);
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
}
.detail-popup-content {
    @include ptsans;
    @include font(#333333, 14px, 30px);
    margin-bottom: 10px;
    padding: 35px 70px;
}

.detail-popup-submit {
    text-align: center;
    a.btn {
        display: inline-block;
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
        +a.btn {
            margin-left: 20px;
        }
    }
}

.fbx-close-cnt {
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    text-align: center;
}

.fbx-close {
    @include ptsans;
    font-weight: normal;
    font-size: 11px;
    color: #fff;
    line-height: 1;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    i {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        margin-bottom: 15px;
    }
}

.fbx-popup-text {
    @include ptsans;
    @include font(#303030, 30px, 44px);
    strong {
        @include ptsans-b;
        font-weight: normal;
    }
}
