.files-list {
    a {
        display: block;
        position: relative;
        &:before {
            content:'';
            position: absolute;
            background-repeat: no-repeat;
        }
        &[href$=".doc"] {
            &:before {
                background-position: -200px -200px;
            }
        }
        &[href$=".docx"] {
            &:before {
                background-position: -200px -200px;
            }
        }
        &[href$=".pdf"] {
            &:before {
                background-position: -250px -200px;
            }
        }
        &[href$=".xls"] {
            &:before {
                background-position: -300px -200px;
            }
        }
        &[href$=".xlsx"] {
            &:before {
                background-position: -300px -200px;
            }
        }
        &[href$=".rar"] {
            &:before {
                background-position: -350px -200px;
            }
        }
        &[href$=".zip"] {
            &:before {
                background-position: -400px -200px;
            }
        }
        &[href$=".7z"] {
            &:before {
                background-position: -400px -200px;
            }
        }
        &[href$=".txt"] {
            &:before {
                background-position: -450px -200px;
            }
        }
    }
}
