    ul.parsley-errors-list {
        list-style: none;
        text-align: center;
        &.filled {
        }
        > li {
            display: block;
            @include ptsans;
            @include font(#f63131, 10px, 16px);
            text-align: center;
            &:before {
                display: none;
            }
        }
    }
