$chosen-sprite: image-url('/img/spr_icons.png') !default;
$chosen-sprite-retina: image-url('/css/plugins/chosen-sprite@2x.png') !default;
.js-common-select {
    &.chosen-container-single {
        width: 100% !important;
        .chosen-single {
            @include border-radius(0);
            @include box-shadow(none);
            background: #fff;
            border:1px solid #e5e5e5;
            @include play;
            @include font(#666666, 11px, 40px);
            height: 40px;
            letter-spacing: 4px;
            text-transform: uppercase;
            padding-left: 12px;
            @include transition(border-color 0.2s, background-color 0.2s, color 0.2s);
            div {
                top: 6px;
                bottom: 6px;
                border-left: 1px solid #e6e6e6;
                width: 36px;
                height: auto;

                b {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 4px 0 4px;
                    border-color: #363636 transparent transparent transparent;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -4px;
                    margin-top: -1px;
                }
            }
            span {
                margin-right: 40px;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 250px;
            }
        }
        .chosen-drop {
            @include border-radius(0);
            border:none;
            background: #333;
            @include box-shadow(none);

            .chosen-results {
                margin:0;
                padding:0;
                li {
                    @include play;
                    @include font(#e5e5e5, 11px, 11px);
                    letter-spacing: 4px;
                    text-transform: uppercase;
                    padding:15px 12px;
                    border-top: 1px solid #4d4d4d;
                    &.highlighted {
                        background: #4d4d4d;
                    }
                    &.result-selected {
                        color: $cYellow;
                    }
                }
            }
        }
    }
    &.chosen-container-active {
        &.chosen-with-drop {
            .chosen-single {
                background: #333;
                color:#e5e5e5;
                border:1px solid #333;
                div {
                    border-left: 1px solid #666;
                    b {
                        border-color: #666 transparent transparent transparent;
                    }
                }
            }
        }
    }
}

.add-coin-params-cnt {
    .js-common-select.chosen-container-active.chosen-with-drop .chosen-single {

    }
    .js-common-select.chosen-container-single .chosen-single span {
        overflow: hidden;
        margin-left: 0;
        margin-right: 40px;
        text-overflow: inherit;
        white-space: inherit;
        max-width: 210px;
    }
}