.inner-gallery-cnt {
    position: relative;
}
.inner-gallery {
    li {
        list-style:none;
        height: 400px;
        @media #{$tablet} {
          height: 300px;
          position: relative;
        }
    }
    .back, .front {
        position: absolute;
        width: 960px;
        height: 400px;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        @media #{$tablet} {
          width: 100%;
          height: 100%;
        }
    }
    .back {
        @include opacity(0.1);
    }
}
.inner-gallery-thumbs-cnt {
    width: 100%;  
    position: relative;
    margin-top: 20px;
    margin-bottom: 50px;
}
.inner-gallery-thumb-list {
    overflow: hidden;
    .thumb-item {
        display: block;
        overflow: hidden;
        width: 156px;
        height: 80px;
        float: left;
        margin-left: 25px;
        @media #{$tablet} {
          width: 100px;
          height: 60px;
          margin-left: 10px;
        }
        &:first-child {
            margin-left: 0;
        }
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border:2px solid transparent;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        @include transition(border-color 0.2s);
        &.active {
            border:2px solid $cYellow;
        }
    }
}