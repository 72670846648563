.js-panes-control {
    list-style: none;
    @media #{$phone} {
      display: flex;
      padding-bottom: 10px;
      overflow-x: scroll;
      white-space: nowrap;
    }
    .js-tab-item {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        &:first-child {
            margin-left: 0;
        }
    }
    .js-pane-trigger {
        display: block;
        position: relative;
    }
}
.js-panes {
    position: relative;
    .js-pane {
        position: relative;
        left:0;
        top:0;
        display: none;
        &.active {
            display: block;
        }
    }
}
.tabs-cnt {
    padding-top: 20px;
}
.tabs-list {
    li {
        padding-left: 0;
        margin-left: 40px;
        margin-bottom: 0;
        @media #{$phone} {
          margin-left: 20px;
        }
        &:first-child {
            margin-left: 0;
        }
        &:before {
            display: none;
        }
        &.active {
        }
        a {
            @include play;
            @include font(#808080, 16px, 1);
            padding-bottom: 10px;
            border-bottom: 2px solid #e5e5e5;
            text-decoration: none;
            @include transition(color 0.2s, border-color 0.2s);
            &:hover {
                color: #333;
                text-decoration: none;
                border-bottom-color: #333;
            }
            &.active {
                color: #333;
                border-bottom-color: #333;
                &:hover {
                    cursor: default;
                }
            }
        }
    }
}