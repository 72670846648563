.breadcrumbs {
    list-style: none;
    li {
        &:first-child {
            &:before {
            }
        }
        &:before {
        }
        a {
        }
    }
}