h1 {
    @include play-b;
    @include font(#f2f2f2, 50px, 54px);
    text-align: center;
    margin-bottom: 25px;
    @media #{$tablet} {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 20px;
    }    
    @media #{$phone} {
      font-size: 35px;
      line-height: 40px;
    }
}
h2 {
    @include play-b;
    @include font(#333333, 34px, 44px);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 40px;
    margin-bottom: 20px;
    .black & {
        color: #ccc;
    }
    @media #{$tablet} {
      margin-top: 30px;
    }
    @media #{$phone} {
      font-size: 30px;
      line-height: 40px;
    }
}
h3 {
    @include play-b;
    @include font(#333333, 30px, 40px);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 40px;
    margin-bottom: 20px;
    .black & {
        color: #ccc;
    }
    @media #{$tablet} {
      margin-top: 30px;
    }
    @media #{$phone} {
      font-size: 26px;
      line-height: 36px;
    }
}
h4 {
    @include play-b;
    @include font(#333333, 30px, 38px);
    margin-top: 40px;
    margin-bottom: 20px;
    .black & {
        color: #ccc;
    }
    @media #{$tablet} {
      margin-top: 30px;
      font-size: 28px;
      line-height: 36px;
    }
    @media #{$phone} {
      font-size: 26px;
      line-height: 36px;
    }
}
h5 {
    @include play-b;
    @include font(#333, 28px, 36px);
    font-style: normal;
    margin-top: 40px;
    margin-bottom: 20px;
    .black & {
        color: #ccc;
    }
    @media #{$tablet} {
      font-size: 24px;
      line-height: 32px;
      margin-top: 30px;
    }
    @media #{$phone} {
      font-size: 22px;
      line-height: 30px;
    }
}
.justlink {
    @include ptsans;
    @include font(#333, 12px, 1);
    text-transform: uppercase;
    margin-top: 20px;
    display: inline-block;
    vertical-align: middle;
    .black & {
        color: #ccc;
    }
}
.text-content {
    img {
      max-width: 100%;
      &.full-width {
      }
      @media #{$phone} {
        height: auto !important;
      }
    }
    p {
        @include ptsans;
        @include font(#333333, 16px, 30px);
        .black & {
            color: #ccc;
        }
        margin-bottom: 14px;
        @media #{$phone} {
          font-size: 14px;
          line-height: 24px;
        }
        &.figure-caption {
        }
        a {
            color: #804518;
            text-decoration: underline;
            &:visited {
                color: #808080;
                &:hover {
                    color: #808080;
                }
            }
            &:hover {
                text-decoration: none;
                color: #cc6e27;
            }
        }
    }
    mark {
    }
    a {
        &[target="_blank"] {
            &:hover {
            }
        }
    }
    blockquote {
        cite {
        }
        &:before {
        }
    }
    ul li, ol li {
        @include ptsans;
        @include font(#333333, 14px, 24px);
        margin-bottom: 15px;
    }
    ul {
        list-style: none;
        >li {
            padding-left: 15px;
            position: relative;
            &:before {
                content:'';
                width: 4px;
                height: 4px;
                background: $cYellow;
                position: absolute;
                left: 0;
                top: 9px;
            }
            >ul {
                >li {
                    &:before {
                    }
                }
            }
        }
    }
    ol {
        list-style: none;
        counter-reset: cnt;
        >li {
            padding-left: 20px;
            position: relative;
            counter-increment: cnt;
            &:before {
                content:counter(cnt)'.';
                position: absolute;
                left: 0;
                color: $cYellow;
            }
            ol {
                margin-top: 10px;
                list-style: none;
                counter-reset: cnt_in;
                >li {
                    padding-left: 25px;
                    position: relative;
                    counter-increment: cnt_in;
                    &:before {
                        content: counter(cnt)'.'counter(cnt_in)'.';
                        position: absolute;
                        left: 0;
                        color: $cYellow;
                    }
                }
            }
        }
    }
    .cut-in {
        @include play-b;
        @include font(#333333, 32px, 46px);
        text-align: center;
        margin-bottom: 60px;
        margin-top: 60px;
    }
    table {
        width: 100%;
        th {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 27px;
            text-align: left;
            @include ptsans;
            @include font(#333, 14px, 16px);
            text-transform: uppercase;
            letter-spacing: 1px;
            border-top: 2px solid #333;
            border-bottom: 2px solid #333;
            @media #{$phone} {
              padding-left: 10px;
              padding-bottom: 10px;
              padding-top: 10px;
              border-width: 1px;
            }
        }
        td {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 27px;
            @include ptsans;
            @include font(#333, 14px, 18px);
            border-top: 1px solid #e6e6e6;
            @media #{$phone} {
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 10px;
            }
        }
        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
            &:nth-child(even) {
                td {
                }
            }
        }
    }
    .table-cnt {
        margin-bottom: 20px;
    }
}
@import "components/gallery";
@import "components/breadcrumbs";
@import "components/pagination";
@import "components/accordeon";
@import "components/tabs";
@import "components/fileslist";
@import "components/tooltip";
.cols {
    &:after {
        content: "";
        display: table;
        clear: left;
    }
}
$ml: 20/960 * 100%;
.col13 {
    width: (100% - 2*$ml)/3;
    float: left;
    margin-left: $ml;
    @media #{$phone} {
      float: none;
      width: 100%;
      margin-left: 0;
    }
    &:first-child {
        margin-left: 0;
    }
}
.col23 {
    width: (100% - $ml) - (100% - 2*$ml)/3;
    float: left;
    margin-left: $ml;
    @media #{$phone} {
      float: none;
      width: 100%;
      margin-left: 0;
    }
    &:first-child {
        margin-left: 0;
    }
    .col12 {
        $ml1: 20/630 * 100%;
        width:(100% - $ml1)/2;
        margin-left: $ml1;
        float: left;
        &:first-child {
            margin-left: 0;
        }
    }
}
.col12 {
    $ml: 20/960 * 100%;
    width:(100% - $ml)/2;
    margin-left: $ml;
    float: left;
    @media #{$phone} {
      width: 100%;
      margin-left: 0;
      //margin-bottom: 20px;
    }
    &:first-child {
        margin-left: 0;
    }
}
hr {
    display: block;
    background: #e6e6e6;
    height: 1px;
    border: 0;
    margin: 20px 0 30px 0;
    padding: 0;
}
.responsive-table {
  @media #{$tablet} {
    overflow-x: scroll;
  }
}