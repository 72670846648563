@import "reset";
@import "compass.scss";
@import "fonts";
@import "variables";
@import "defaults";
@import "mixins";
@import "/css/plugins/jquery.mCustomScrollbar.min.css";
@import "/css/plugins/jquery.fancybox3.css";
@import "/css/plugins/chosen.min.css";
@import "/css/plugins/tooltipster.css";
@import "/css/plugins/daterangepicker.css";
/* ==========================================================================
                MAIN STYLES
   ========================================================================== */
html {
    height: auto;
    min-height: 100%;
}
body {
    min-width: 1020px;
    @include ptsans;
    @media #{$tablet} {
      min-width: 100%;
    }
    &.events, &.services, &.archive {
        background: #1c1c1c;
    }
    &.event, &.messages, &.members, &.award {
        background: #fff;
    }
    &.lock {
        overflow: hidden;
    }
    &.about {

    }
}
#site-wrapper {
    min-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    //overflow: hidden;
    padding-bottom: 50px;
    position: relative;
    @media #{$tablet} {
      min-width: 100%;
      padding-bottom: 71px;
    }
    @media #{$phone} {
      padding-bottom: 81px;
    }
    .index & {
        position: relative;
        background: url('/img/bg_main.jpg') 50% 50% no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .item & {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
    }
}
#root-wrapper {
    position: relative;
    @include perspective(2000px);
    overflow-x: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    &.initialised {
      opacity: 1;
    }
}
.inner-content {
    padding-top: 170px;
    .collections &, .collection &, .search &, .event &, .award &, .about & {
        padding-top: 0;
    }
    h1 {
        color: #333;
    }
}
/*==================================================
                COMMON BLOCKS
==================================================*/
.inner-content {
}
.content-wrapper {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 1000px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    @media #{$tablet} {
      width: 100%;
    }
    @media #{$phone-sm} {
      padding: 0 10px;
    }
}
.backlink {
    position: absolute;
    left: 20px;
    top: 70px;
    @include play-b;
    @include font(#999999, 12px, 1);
    text-transform: uppercase;
    letter-spacing: 2px;
    @include transition(color 0.2s);
    z-index: 2;
    @media #{$phone} {
      top: 42px;
    }
    &:hover {
        text-decoration: none;
        color: darken(#999, 10%);
    }
    &:before {
        margin-right: 7px;
        font-size: 16px;
        vertical-align: sub;
    }
}
.page-header {
    @include play;
    @include font(#808080, 20px, 1);
    padding-bottom: 13px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
}
.inner-header-picture {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    &:after {
        content:'';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(26, 26, 26, 0.55);
    }
    .lt-ie9 & {
        &:after {
            background: url('/img/bg_darken.png') 0 0 repeat;
        }
    }
}
.inner-header {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 170px;
    padding-bottom: 55px;
    @media #{$tablet} {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      padding-top: 120px;
    }
    h1 {
        width: 760px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        color: #f2f2f2;
        @media #{$tablet} {
          width: 100%;
        }

    }
}
.inner-subhead {
    width: 820px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include ptsans;
    @include font(#f2f2f2, 16px, 24px);
    margin-bottom: 20px;
    @media #{$tablet} {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }
}
/*==================================================
                SITE HEADER
==================================================*/
.site-header {
    height: 110px;
    position: relative;
    margin-bottom: 50px;
}
.logo {
    position: absolute;
    width: 66px;
    height: 66px;
    background: url('/img/logo.png') 50% 50% no-repeat;
    @include opacity(0.4);
    @include transition(opacity 0.2s);
    text-decoration: none;
    left: 50%;
    margin-left: -33px;
    top: 46px;
    z-index: 2;
    @media #{$phone} {
      top: 20px;
      opacity: .8;
    }
    @media (max-width: 926px) and (orientation: landscape) {
      top: 20px;
    }
    .index & {
        @include opacity(0.6);
    }
    &:hover {
        @include opacity(0.8);
    }
}
.site-nav-trigger {
    position: absolute;
    right: 20px;
    top: 70px;
    @include play-b;
    @include font(#999999, 12px, 1);
    text-transform: uppercase;
    letter-spacing: 2px;
    @include transition(color 0.2s);
    z-index: 2;
    @media #{$phone} {
      top: 42px;
      color: rgba(255, 255, 255, 0.8);
    }
    @media (max-width: 926px) and (orientation: landscape) {
      top: 42px;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
    }
    .members &, .grants &, .item &, .services-inner &, .lk & {
        &:hover {
            color: #666;
        }
    }
    &:before {
        font-size: 26px;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 10px;
        height: 18px;
        display: inline-block;
        line-height: 18px;
    }
    .index & {
        display: none;
    }
}
/*==================================================
                INDEX PAGE
==================================================*/
.index-slogan-cnt {
    text-align: center;
    @media #{$phone} {
      margin-top: 60px;
      padding: 0 10px;
    }
    .slogan {
        @include play;
        @include font(#fff, 48px, 70px);
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 40px;
        @media #{$phone} {
          font-size: 30px;
          line-height: 42px;
        }
        @media #{$phone-sm} {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 30px;
        }
        @media (max-width: 926px) and (orientation: landscape) {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 30px;
        }
        &:after {
            content:'';
            width: 300px;
            height: 1px;
            background-color: #737373;
            position: absolute;
            left: 50%;
            margin-left: -150px;
            bottom: -12px;
        }
    }
    .subhead {
        @include play-b;
        @include font(#ffffff, 12px, 1);
        text-transform: uppercase;
        letter-spacing: 6px;
        @media #{$phone} {
          line-height: 16px;
        }
    }
}
.index-nav-cnt {
    max-width: 1024px;
    margin-top: 120px;
    @media #{$phone} {
      margin-top: 60px;
    }
    @media #{$phone-sm} {
      margin-top: 40px;
    }
    @media (max-width: 926px) and (orientation: landscape) {
      margin-top: 40px;
    }
    @media (max-width: 568px) and (orientation: landscape) {
      margin-top: 20px;
    }
}
.index-nav {
    @media #{$phone} {
      display: flex;
      flex-wrap: wrap;
    }
    li {
        display: inline-block;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        vertical-align: middle;
        padding-left: 33px;
        padding-right: 33px;
        position: relative;
        margin-bottom: 80px;
        @media #{$phone} and (orientation: portrait){
          width: 100%;
          margin-bottom: 20px;
          display: block;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 20px;
        }
        @media #{$phone-sm} and (orientation: portrait) {
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
        @media (max-width: 926px) and (orientation: landscape) {
          margin-bottom: 0;
        }
        &:before {
            content:'';
            position: absolute;
            left: 0;
            top: 50%;
            height: 55px;
            width: 1px;
            background: #fff;
            @include opacity(0.3);
            margin-top: -27px;
            @media #{$phone} and (orientation: portrait) {
              margin-top: 0;
              height: 1px;
              left: 0;
              width: 100%;
              top: -20px;
            }
            @media #{$phone-sm} and (orientation: portrait) {
              top: -15px;
            }
            @media (max-width: 926px) and (orientation: landscape) {
              height: 40px;
              margin-top: -20px;
            }
            @media (max-width: 568px) and (orientation: landscape) {
              height: 30px;
              margin-top: -15px;
            }
        }
        &:first-child {
            &:before {
                display: none;
            }
        }
        &:last-child {
            &:after {
                content:'';
                position: absolute;
                right: 0;
                top: 50%;
                height: 55px;
                width: 1px;
                background: #fff;
                @include opacity(0.3);
                margin-top: -27px;
                @media (max-width: 926px) and (orientation: landscape) {
                  height: 40px;
                  margin-top: -20px;
                }
                @media (max-width: 568px) and (orientation: landscape) {
                  height: 30px;
                  margin-top: -15px;
                }
            }
        }
        a {
            @include play-b;
            @include font(#fff, 13px, 20px);
            text-transform: uppercase;
            letter-spacing: 4px;
            display: block;
            @include transition(color 0.2s);
            @media #{$phone} {
              font-size: 12px;
              line-height: 18px;
            }
            @media #{$phone-sm} {
              font-size: 11px;
              line-height: 16px;
            }
            &:hover {
                text-decoration: none;
                color: $cYellow;
            }
        }
    }
}
.index-login {
    position: absolute;
    @include play-b;
    @include font(#999999, 11px, 1);
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    top: 70px;
    right: 20px;
    @include transition(color 0.2s);
    &:hover {
        color: #fff;
        text-decoration: none;
    }
    @media #{$phone} {
      top: 42px;
      color: rgba(255, 255, 255, 0.8);
    }
    @media (max-width: 926px) and (orientation: landscape) {
      top: 42px;
    }
}
.index-menu {
    position: absolute;
    right: 20px;
    top: 70px;
    @include play-b;
    @include font(#999999, 12px, 1);
    text-transform: uppercase;
    letter-spacing: 2px;
    @include transition(color 0.2s);
    z-index: 2;
    @media #{$phone} {
      top: 42px;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
    }
    &:before {
        font-size: 26px;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 10px;
        height: 18px;
        display: inline-block;
        line-height: 18px;
    }
}
/*==================================================
                EVENTS PAGE
==================================================*/
.events {
    h1 {
        color: #f2f2f2;
    }
}
.events-archive-link {
    @include play;
    @include font(#808080, 20px, 1);
    @include transition(color 0.2s);
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: #f2f2f2;
    }
    &:before {
        color: #f2cb58;
        display: inline-block;
        margin-right: 15px;
        vertical-align: baseline;
        font-size: 18px;
    }
}
.events-cnt {
    width: 100%;
    margin-top: 10px;
}
.events-scroller {
    position: relative;
    overflow: hidden;
    padding-top: 40px;
}
.events-list {
    overflow: hidden;
    @media #{$tablet} {
      padding: 0 20px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
    }
    @media #{$phone} {
      margin-left: 0;
    }
}
.event-item {
    width: 304px;
    float: left;
    margin-right: 40px;
    list-style: none;
    &+.event-item {
      @media #{$tablet} {
        margin-top: 20px;
      }
    }
    @media #{$tablet} {
      width: calc((100% / 3) - 20px);
      margin-left: 20px;
      margin-right: 0;
      float: none;
    }
    @media #{$phone} {
      width: 100%;
      margin-left: 0;
    }
    &:first-child {
        margin-left: 40px;
        @media #{$tablet} {
          margin-left: 20px;
        }
        @media #{$phone} {
          margin-left: 0;
        }
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: none;
            .img-cnt {
                @include opacity(0.6);
            }
        }
    }
    .img-cnt {
        -webkit-background-size: cover;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 140px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        @include transition(opacity 0.2s);
        @media #{$phone} {
          height: 200px;
        }
    }
}
.event-date {
    @include play-b;
    @include font(#b2b2b2, 11px, 1);
    margin-bottom: 10px;
    padding-left: 10px;
    position: relative;
    &:before {
        content:'';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 4px;
        background: #f2cb58;
    }
}
.event-title {
    padding-left: 10px;
    padding-right: 10px;
    @include play-b;
    @include font(#e5e5e5, 16px, 22px);
    margin-bottom: 15px;
}
.event-text {
    padding-left: 10px;
    padding-right: 10px;
    @include ptsans;
    @include font(#ebebeb, 11px, 16px);
}
.event-inner-author {
    width: 680px;
    margin-left: auto;
    margin-right: auto;
    @include ptsans-b;
    text-align: right;
    font-size: 14px;
}
/*==================================================
                EVENT INNER PAGE
==================================================*/
.event {
}
.event-inner-date {
    @include play-b;
    @include font(#f2f2f2, 10px, 1);
    text-align: center;
}
.event-inner {
    position: relative;
}
.event-inner-text {
    padding-top: 50px;
    @media #{$tablet} {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    p {
        width: 680px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        @include ptsans;
        @include font(#333333, 14px, 30px);
        margin-bottom: 16px;
        @media #{$tablet} {
          width: 100%;
        }
        &:first-child {
            @include font(#333333, 22px, 36px);
            @media #{$tablet} {
              font-size: 18px;
              line-height: 32px;
            }
            &::first-letter {
                font-size: 64px;
                float: left;
                line-height: 78px;
                margin-right: 12px;
                @media #{$tablet} {
                  font-size: 54px;
                  line-height: 66px;
                  margin-right: 10px;
                }
            }
        }
    }
    img {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        @media #{$tablet} {
          max-width: 100%;
          height: auto;
          margin: 20px 0;
        }
    }
}
.event-inner-docs {
    position: absolute;
    left: 50%;
    margin-left: 370px;
    top: 0;
    padding-top: 85px;
    &:before {
        content:'';
        position: absolute;
        width: 40px;
        height: 1px;
        left: 0;
        top: 60px;
        background: #808080;
    }
    a {
        display: block;
        position: relative;
        padding-left: 38px;
        background: url('/img/icon_file.png') 0 0 no-repeat;
        @include ptsans;
        @include font(#333333, 11px, 15px);
        min-height: 45px;
        margin-bottom: 20px;
        span {
            @include ptsans;
        }
        &:before {
            position: absolute;
            left: 5px;
            top: 24px;
            @include ptsans;
            @include font(#333333, 10px ,1);
            background-repeat: no-repeat;
        }
        &[href$=".doc"] {
            &:before {
                content: 'DOC';
            }
        }
        &[href$=".docx"] {
            &:before {
                content:'DOCX';
                left: 3px;
                font-size: 9px;
            }
        }
        &[href$=".pdf"] {
            &:before {
                content:'PDF';
            }
        }
        &[href$=".xls"] {
            &:before {
                content:'XLS';
            }
        }
        &[href$=".xlsx"] {
            &:before {
                content:'XLSX';
                left: 3px;
                font-size: 9px;
            }
        }
        &[href$=".rar"] {
            &:before {
                content:'RAR';
            }
        }
        &[href$=".zip"] {
            &:before {
                content: 'ZIP';
            }
        }
        &[href$=".7z"] {
            &:before {
                content:'7Z';
            }
        }
        &[href$=".txt"] {
            &:before {
                content:'TXT';
            }
        }
    }
}
/*==================================================
                CHATS PAGE
==================================================*/
.chats {
    .page-header {
        margin-left: 20px;
        margin-right: 20px;
    }
    h1 {
        color: #333;
    }
}
.chats-cnt {
}
.chat-list {
}
.chat-item {
    padding-left: 10px;
    padding-right: 10px;
    list-style: none;
    a {
        padding: 30px 10px;
        display: block;
        overflow: hidden;
        text-decoration: none;
        border-bottom: 1px solid #f5f5f5;
        @include transition(background-color 0.2s);
        @media #{$phone} {
          display: flex;
          padding: 20px 0;
        }
        &:hover {
            background: #f5f5f5;
            text-decoration: none;
        }
    }
    .user-cnt {
        width: 210px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-right: 10px;
        @media #{$phone} {
          width: 40%;
          padding-right: 20px;
        }
        .img-cnt {
            width: 60px;
            height: 60px;
            line-height: 60px;
            float: left;
            @include border-radius(3px);
            overflow: hidden;
            @media #{$phone} {
              float: none;
              margin-bottom: 10px;
            }
            img {
                max-width: 60px;
                max-height: 60px;
                display: inline-block;
                vertical-align: middle;
                @media #{$phone} {
                  object-fit: cover;
                }
            }
        }
        .user-info {
            margin-left: 70px;
            @media #{$phone} {
              margin-left: 0;
            }
        }
        .user-name {
            @include ptsans-b;
            @include font(#333333, 11px, 16px);
        }
        .chat-date {
            @include ptsans;
            @include font(#4c4c4c, 10px, 20px);
        }
    }
    .message-cnt {
        margin-left: 210px;
        @media #{$phone} {
          margin-left: 0;
        }
        .img-cnt {
            width: 50px;
            height: 50px;
            float: left;
            @include border-radius(3px);
            overflow: hidden;
            img {
                max-width: 50px;
                max-height: 50px;
            }
            +.message {
                margin-left: 70px;
            }
        }
        .message {
            @include ptsans;
            @include font(#333333, 10px, 17px);
        }
    }
}
/*==================================================
                CHAT INNER PAGE
==================================================*/
.chat {
    h1 {
        color: #333;
    }
}
.messages-cnt {
    padding-top: 15px;
}
.messages-list {
}
.message-item {
    list-style:none;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    aside {
        float: left;
        width: 70px;
    }
    .img-cnt {
        width: 70px;
        height: 70px;
        line-height: 70px;
        overflow: hidden;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-align: center;
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 70px;
            max-height: 70px;
        }
    }
    .message-time-cnt {
        float: right;
        width: 70px;
        text-align: right;
        position: relative;
        padding-top: 14px;
        @include ptsans;
        @include font(#4c4c4c, 10px ,14px);
        &:before {
            content:'';
            position: absolute;
            width: 30px;
            height: 1px;
            background: #e6e6e6;
            right: 0;
            top: 0;
        }
    }
}
.msg-section {
    margin-right: 70px;
    margin-left: 100px;
    .msg-author {
        @include ptsans-b;
        @include font(#333333, 11px, 1);
        margin-bottom: 8px;
    }
    .msg-text {
        @include ptsans;
        @include font(#333333, 12px, 20px);
    }
}
.message-send-form-cnt {
    margin-left: 100px;
    margin-right: 70px;
    textarea {
        height: 120px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px;
        @include ptsans;
        @include font(#333333, 12px, 20px);
    }
    fieldset {
        margin-bottom: 20px;
    }
    input[type=submit] {
        border:1px solid #999;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        @include play;
        @include font(#333, 11px, 40px);
        text-transform: uppercase;
        letter-spacing: 4px;
        background: #fff;
        @include transition(border-color 0.2s);
        &:hover {
            border-color:$cYellow;
            cursor: pointer;
        }
    }
}
/*==================================================
                MEMBERS PAGE
==================================================*/
.members {
    h1 {
        color: #333;
    }
}
.members-cnt {
}
.members-list {
    overflow: hidden;
    margin-left: -4px;
}
.member-item {
    list-style: none;
    width: 237px;
    height: 237px;
    margin-left: 4px;
    margin-bottom: 4px;
    float: left;
    a {
        display: block;
        height: 100%;
        overflow: hidden;
        position: relative;
        background: #000000;
    }
    img {
        @include absolute_center;
        @include opacity(1);
        @include transform(scale(1.12), translateZ(0));
        @include transition(opacity 0.35s, transform 0.35s);
        width: 100%;
    }
    .caption {
        padding-bottom: 18px;
        padding-left: 8px;
        padding-right: 8px;
        position: absolute;
//        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        &:before {
            content: '';
            position: absolute;
            width: 227px;
            height: 227px;
            bottom: 4px;
            left: 4px;
//            left: 4px;
//            right: 4px;
//            top: 4px;
//            bottom: 4px;
            border: 1px solid #fff;
            @include transform(scale(1.1));
            @include opacity(0);
            @include transition(opacity 0.35s, transform 0.35s);
        }
    }
    .name {
        @include transform(scale(1.5));
        @include opacity(0);
        @include transition(opacity 0.35s, transform 0.35s);
        @include play-b;
        @include font(#fff, 16px, 22px);
    }
    .job {
        @include transform(scale(1.5));
        @include opacity(0);
        @include transition(opacity 0.35s, transform 0.35s);
        @include ptsans;
        @include font(#fff, 11px, 16px);
    }
    &:hover {
        .caption {
            &:before {
                @include opacity(0.4);
                @include transform(scale(1));
            }
        }
        img {
            @include opacity(0.6);
            @include transform(scale(1), translateZ(0));
        }
        .name {
            @include opacity(1);
            @include transform(scale(1));
        }
        .job {
            @include opacity(0.6);
            @include transform(scale(1));
        }
    }
}
.details-link {
    @include ptsans;
    @include font(#804518, 14px, 1);
    border-bottom: 1px dotted #dec6b5;
    display: inline-block;
    &:hover {
        text-decoration: none;
    }
}
/*==================================================
                MEMBERS INNER PAGE
==================================================*/
.member {
}
.member-cnt {
    overflow: hidden;
    padding-top: 30px;
    >div {
        float: left;
    }
    .name-cnt {
        width: 210px;
        padding-top: 40px;
    }
    .img-cnt {
        width: 330px;
        text-align: center;
        img {
            max-width: 90%;
            display: inline-block;
        }
    }
    .info-cnt {
        width: 420px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 20px;
        padding-top: 40px;
        position: relative;
//        &:before {
//            content:'';
//            width: 50px;
//            height: 2px;
//            background: #333;
//            position: absolute;
//            left: 20px;
//            top: 40px;
//        }
        @include ptsans;
        @include font(#333333, 14px, 28px);
        p {
            margin-bottom: 14px;
        }
    }
    .name {
        @include play-b;
        @include font(#000, 22px, 32px);
    }
}
/*==================================================
                COLLECTIONS PAGE
==================================================*/
.collections-inner {
//    padding-left: 20px;
//    padding-right: 20px;
    padding-top: 20px;
}
.category-selector {
    margin-bottom: 30px;
    @media #{$phone} {
      display: flex;
      margin-bottom: 20px;
    }
    &.search-selector {
        a {
            width: 50%;
        }
    }
    &.collection-selector {
        a {
            width: percentage(1/3);
        }
    }
    a {
        display: inline-block;
        border-bottom: 1px solid #e6e6e6;
        height: 60px;
        @include play;
        @include font(#333333, 14px, 60px);
        text-align: center;
        @include transition(border-color 0.2s, color 0.2s);
        @media #{$phone} {
          height: auto;
          line-height: 1.2;
          padding-bottom: 10px;
        }
        &:hover {
            text-decoration: none;
            border-bottom: 1px solid $cYellow;
            color: $cYellow;
        }
        &.active {
            @include play-b;
            border-bottom-color: #333;
            color: #333;
        }
    }
}
.search-input-cnt {
    height: 40px;
    line-height:40px;
    border:1px solid #e6e6e6;
    margin-bottom: 20px;
    @media #{$tablet} {
      form {
        display: flex;
      }
    }
    input[type=text] {
        display: inline-block;
        height: 28px;
        @include play;
        border:none;
        text-align: left;
        @include font(#666, 11px, initial);
        text-transform: uppercase;
        letter-spacing: 4px;
        width: 870px;
        border-right:1px solid #e6e6e6;
        vertical-align: middle;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 15px;
        @media #{$tablet} {
          width: calc(100% - 88px);
          height: 40px;
        }
        &:focus {
            outline: none;
            border:none;
            border-right: 1px solid #e6e6e6;
        }
    }
    a.search-submit {
        display: inline-block;
        width: 88px;
        height: 28px;
        @include play;
        @include font(#666666, 11px, 28px);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center;
        @include transition(color 0.2s);
        vertical-align: middle;
        @media #{$tablet} {
          height: 40px;
          line-height: 40px;
        }
        &:before {
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
        }
        &:hover {
            text-decoration: none;
            color: $cYellow;
        }
    }
}
.items-list-cnt {
}
.items-list {
    overflow: hidden;
    li {
        list-style:none;
        height: 400px;
        float: left;
        width: percentage(1/3);
        border: 1px solid #fff;
        border-bottom-color: #e6e6e6;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        @include transition(border-color 0.2s);
        @media #{$phone} {
          width: 100%;
          height: auto;
          &+.item-list {
            margin-top: 20px;
          }
        }
        &:hover {
            border-color: #f2cb58;
        }
        &.empty {
            width: 100%;
            @include play-b;
            @include font(#000, 18px, 150px);
            text-align: center;
            height: 150px;
            border:none;
        }
    }
    a {
        display: block;
        height: 100%;
        overflow: hidden;
        position: relative;
        @media #{$phone} {
          padding-bottom: 20px;
        }
        &:after {
            content:'';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100px;
            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
            @media #{$phone} {
              content: none;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
    .img-cnt {
        height: 300px;
        line-height: 300px;
        text-align: center;
        margin-bottom: 10px;
        @media #{$phone} {
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center
        }
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 90%;
            max-height: 90%;
        }
    }
    &.items-coins-list {
        .img-cnt {
            position: relative;
            @include perspective(1000px);
            width: 100%;
            .front, .back {
                display: block;
                position: absolute;
                width: 100%;
                height: 300px;
                @include backface-visibility(hidden);
                @media #{$phone} {
                  height: 200px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
            }
            .back {
                @include transform(rotateY(180deg));
            }
            .lt-ie9 &, .bx-ie9 & {
                .back {
                    display: none;
                }
            }
            &:hover {
                .coin-image-flipper {
                    @include transform(rotateY(180deg));
                }
            }
        }
        .coin-image-flipper {
            position: absolute;
            width: 100%;
            height: 100%;
            @include transform-style(preserve-3d);
            @include transition(all 0.8s);
            @include backface-visibility(hidden);
            //&:hover {
            //
            //
            //}
            .lt-ie9 &, .bx-ie9 & {
                &:hover {
                    .front {
                        display: none;
                    }
                    .back {
                        display: block;
                    }
                }
            }
        }
        .coin-noimage {
            @include ptsans;
            color: $cBlack;
        }
    }
}
.item-descr-cnt {
    p {
        padding-left: 30px;
        padding-right: 50px;
        @include ptsans;
        @include font(#333333, 16px, 22px);
    }
}
/*==================================================
                STYLES PAGE
==================================================*/
@import "styles";
/*==================================================
                COINS FIND PAGE
==================================================*/
.search-pane {
    padding-top: 18px;
    padding-bottom: 18px;
}
.era-col {
    float: right;
    width: percentage(115/300);
}
.date-col {
    float: left;
    width: percentage(175/300);
    &:first-child {
        width: percentage(145/300);
        +.date-col {
            width: percentage(145/300);
            margin-left: percentage(10/300);
        }
    }
}
.filter-select-holder {
    @include transition(opacity 0.2s, visibility 0.2s, transform 0.2s);
    &.hide {
        @include transform(translateY(-40px));
        @include opacity(0);
        visibility: hidden;
    }
}
.search-result-filter {
    .params-cnt {
        margin-bottom: 20px;
        @include ptsans;
        @include font(#333, 14px, 20px);
    }
}
.filter-cnt {
    @include transition(height 0.2s, overflow 0s 0.3s);
    &.collapsed {
        height: 0!important;
    }
}
.filter-header {
    display: inline-block;
    position: relative;
    margin-right: 30px;
    &:after {
        content:'';
        width: 1px;
        height: 20px;
        background: #e6e6e6;
        right: -15px;
        top: 0;
        position: absolute;
    }
}
.filter-item {
    display: inline-block;
    color: #808080;
}
.filter-trigger {
    display: inline-block;
    border-bottom: 1px dotted #333;
    margin-left: 20px;
    color: #333;
    &:hover {
        text-decoration: none;
    }
}
.js-coins-list {
    position: relative;
}
/*==================================================
                LK PAGE
==================================================*/
.lk {
    .form-submit-cnt {
        margin-top: 25px;
    }
}
.lk-profile-cnt {
    padding-top: 30px;
    position: relative;
    .coin-remove {
        position: absolute;
        right: 0;
        top: -5px;
        font-family: amazing_groteskregular, Georgia, serif;
        font-weight: normal;
        font-size: 11px;
        color: #333;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 4px;
    }
    form {
    }
}
.lk-profile-aside {
    width: 400px;
    float: right;
    @media #{$tablet} {
      width: 300px;
    }
    @media #{$phone} {
      width: 100%;
      float: none;
    }
}
.lk-profile-photo-cnt {
    margin-bottom: 20px;
    width: 400px;
    height: 400px;
    padding: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border:1px solid #e5e5e5;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    user-select: none;
    @media #{$tablet} {
      width: 300px;
      height: 300px;
      padding: 10px;
    }
    @media #{$phone} {
      width: 100%;
      padding-bottom: 100%;
      height: auto;
    }
    .lk-profile-ph-preview {
      display: block;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-position: 50% 0%;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .lk-profile-ph-text {
      display: block;
      position: absolute;
      top: 15px; left: 15px;
      z-index: 5;
      width: 370px;
      height: 370px;
      background-color: #e5e5e5;
      span {
        display: block;
        position: absolute;
        top: 160px; left: 0px;
        width: 370px;
        text-align: center;
        @include ptsans-b;
        @include font(#000,16px,20px);
      }
    }
}
.lk-profile-photo-uploader {
  display: block;
  overflow: hidden;
  margin-left: -5px;
  .add-file-box {
    display: block;
    position: absolute;
    left: -100px;
    top: 0;
    width: 0;
    height: 0;
      overflow: hidden;
  }
  .add-file {
    display: block;
    width: 5px;
    height: 5px;
  }
  .up-image {
    position: relative;
    display: block;
    overflow: hidden;
    float: left;
    border: 2px solid #e6e6e6;
    width: 56px;
    height: 56px;
    margin: 0 0 5px 5px;
    background-color: #e6e6e6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: background-color .2s 0s, border .2s 0s;
    &.up-add {
      background-color: #e6e6e6;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABlBMVEUzMzMzMzNOAAY6AAAAAXRSTlMAQObYZgAAABhJREFUeAFjgANGRjhzyAgyYgFYBYe0NwE//ABNE2hyQQAAAABJRU5ErkJggg==);
    }
    &.up-preview {
      background-color: #e6e6e6;
      background-position: 50% 0%;
      background-repeat: no-repeat;
      background-size: cover;
      i {
        display: block;
        position: absolute;
        opacity: 0;
        top: 0px; right: 0px;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #ddd;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAMAAADXT/YiAAAAD1BMVEX///8AAAAAAAAAAAAAAABRO2rwAAAABHRSTlMAjtDdIWI7ZgAAACtJREFUeNpFjFEOAAAIQVH3P3Pyw+ZNLWGIFwdcx2cQZ5ZDpJXZXe/a7b8DEToAYdE5nzkAAAAASUVORK5CYII=);
        transition: opacity .2s 0s;
        &:hover {
          background-color: #fff;
        }
      }
      &:hover {
        i { opacity: 1; }
      }
    }
    &:hover {
      background-color: #bbb;
      border-color: #bbb;
    }
  }
}
.lk-profile-photo {
    display: block;
    position: absolute;
    left: 25px;
    top: 25px;
    bottom: 25px;
    right: 25px;
    background: #e6e6e6;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
    &:hover {
        cursor: pointer;
        &:before {
            visibility: visible;
            @include opacity(0.5);
        }
        .change-photo-text {
            @include opacity(1);
            @include transform(scale(1));
        }
    }
    &:before {
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        @include opacity(0);
        visibility: hidden;
        left: 0;
        top: 0;
        @include transition(opacity 0.2s, visibility 0.2s);
    }
    .change-photo-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -6px;
        @include opacity(0);
        @include transform(scale(2));
        @include transition(opacity 0.2s, transform 0.2s);
        @include ptsans-b;
        @include font(#fff, 14px, 1);
        text-align: center;
    }
}
#profile-photo {
    position: fixed;
    top: -40px;
}
.fileinput {
    visibility: hidden;
    width: 0;
    height: 0;
    .lt-ie9 &{
        visibility: visible;
    }
}
.lk-profile-inner {
    margin-right: 420px;
    @media #{$tablet} {
      margin-right: 320px;
    }
    @media #{$phone} {
      margin-right: 0;
      margin-top: 20px;
    }
}
.lk-inner-section {
    margin-bottom: 25px;
}
.lk-section-header {
    @include play-b;
    @include font(#333, 16px, 24px);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
}
.lk-tooltip-trigger {
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background: #ebebeb;
    text-align: center;
    @include ptsans;
    @include font(#303030, 10px, 14px);
    @include transition(background-color 0.2s);
    &:hover {
        cursor: pointer;
        background: darken(#ebebeb, 15%);
    }
}
.lk-edit-trigger {
    color: #333;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    &:hover {
        text-decoration: none;
    }
}
.lk-section-text {
    @include ptsans;
    @include font(#333, 14px, 24px);
    textarea {
        @include ptsans;
        @include font(#333, 14px, 24px);
        text-transform: none;
        letter-spacing: normal;
    }
}
.lk-collection-control-cnt {
    position: relative;
    overflow: hidden;
    >h4 {
        margin-top: 0;
    }
    .collection-remove {
        position: absolute;
        right: 0;
        top: 20px;
        @include play;
        @include font(#333, 12px, 24px);
        text-transform: uppercase;
        &:before {
            display: inline-block;
            vertical-align: middle;
            height: 20px;
            font-size: 16px;
            margin-right: 5px;
        }
    }
}
.lk-collection-keywords {
}
.keyword {
    display: inline-block;
    @include ptsans;
    @include font(#666666, 10px, 1);
    padding: 5px;
    background: #e3e4e6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.add-coins {
    color: #333333;
    font-size: 11px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-left: 30px;
    &:before {
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        margin-right: 10px;
        @include transform-origin(50%,50%);
        @include transition(transform 0.2s);
    }
    &:hover {
        text-decoration: none;
        &:before {
            @include transform(scale(1.2));
        }
    }
}
.lk-coins-list {
    &.checkable {
        width: 980px;
        margin-left: -20px;
        @media #{$tablet} {
          width: 100%;
        }
        @media #{$phone} {
          margin-left: 0;
        }
    }
    .empty {
        width: 100%;
        @include play-b;
        @include font(#000, 18px, 150px);
        text-align: center;
        height: 150px;
        border:none;
    }
}
.lk-coin-item {
    position: relative;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    margin-bottom: 40px;
    width: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @media #{$phone} {
      width: 100%;
      margin-bottom: 20px;
    }
    .checkable & {
        padding: 20px;
        @include transition(background-color 0.2s);
        @media #{$phone} {
          padding: 0;
        }
        .lk-coin-item-info {
            p {
                text-transform: uppercase;
                font-size: 10px;
            }
        }
        &:hover {
            cursor: pointer;
            background: #f2f2f2;
            @media #{$phone} {
              background: transparent;
            }
        }
        &.active {
            .img-cnt {
                &:before {
                    content:'g';
                    background: #ccc;
                    border-color: #ccc;
                }
            }
        }
    }
}
.lk-coin-item-aside {
    float: left;
    width: 120px;
    .img-cnt {
        width: 120px;
        height: 120px;
        line-height: 120px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border:1px solid #e5e5e5;
        text-align: center;
        background: #fff;
        position: relative;
        img {
            max-width: 100px;
            max-height: 100px;
            display: inline-block;
            vertical-align: middle;
        }
        .checkable & {
            &:before {
                content:'';
                font-family: "nmk" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 10px;
                color: #333333;
                line-height: 23px;
                width: 23px;
                height: 23px;
                border: 1px solid #e6e6e6;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                position: absolute;
                top: -1px;
                right: -1px;
                @include transition(background-color 0.2s, border-color 0.2s);
            }
        }
    }
}
.lk-coin-item-inner {
    margin-left: 140px;
}
.lk-coin-item-header {
    text-decoration: none;
    margin-bottom: 25px;
    position: relative;
    &:after {
        content:'';
        width: 40px;
        height: 2px;
        position: absolute;
        bottom: -10px;
        background: #e3e4e6;
        left: 0;
    }
}
.lk-coin-item-name {
    @include play;
    @include font(#303030, 16px, 24px);
}
.lk-coin-item-info {
    p {
        @include ptsans;
        @include font(#303030, 12px, 1);
        margin-bottom: 12px;
    }
    strong {
        @include ptsans-b;
    }
}
.lk-coin-item-remove {
    text-decoration: none;
    color: #303030;
    &:before {
        display: inline-block;
        margin-right: 5px;
        font-size: 16px;
        margin-top: -3px;
        vertical-align: middle;
    }
}
.col-from {
    float: left;
    width: 230px;
}
.col-actions {
    float: left;
    width: 26px;
    margin-left: 17px;
    height: 166px;
    padding-top: 20px;
    line-height: 166px;
    .btn {
        height: 26px;
        padding: 0;
        width: 26px;
        line-height: 26px;
        text-align: center;
        padding-left: 4px;
        &:before {
            display: inline-block;
            vertical-align: middle;
        }
    }
}
.col-to {
    float: left;
    width: 230px;
    margin-left: 17px;
}
.move-list-cnt {
    width: 230px;
    height: 166px;
    overflow: hidden;
    position: relative;
    border:1px solid #e6e6e6;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    +.link {
        display: inline-block;
        @include ptsans;
        @include font(#666666, 10px, 1);
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-left: 15px;
        margin-top: 8px;
    }
}
.move-list {
}
.move-item {
    list-style: none;
    display: block;
    padding: 8px 14px;
    @include ptsans;
    @include font(#333, 11px, 1);
    text-transform: uppercase;
    letter-spacing: 4px;
    @include transition(background-color 0.2s);
    &:hover, &.active {
        cursor: pointer;
        background: #e6e6e6;
    }
}
p.label {
    @include play;
    padding-left: 12px;
    @include font(#666666 ,12px, 1);
    margin-bottom: 8px;
    display: block;
}
.add-collection-submit-cnt {
    a.btn, input[type=submit] {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        +a.btn, +input[type=submit] {
            margin-left: 20px;
        }
    }
    input {
        padding-left: 35px;
        padding-right: 35px;
    }
}
.request-cnt {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    @media #{$phone} {
      width: 100%;
    }
}
.w230 {
    width: 230px;
}
/*==================================================
                SERVICES PAGE
==================================================*/
.services {
    h1 {
        color: #f2f2f2;
    }
}
.services-list-cnt {
    padding-top: 30px;
    margin-bottom: 30px;
    padding: 0 20px;
}
.services-list {
    margin-left: -60px;
    @media #{$tablet} {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
    }
    @media #{$phone} {
      margin-left: 0;
    }
}
.service-item {
    list-style-type: none;
    width: 450px;
    height: 150px;
    margin-left: 60px;
    float: left;
    @media #{$tablet} {
      float: none;
      width: calc((100% / 2) - 30px);
      height: auto;
      margin-bottom: 20px;
      margin-left: 20px;
    }
    @media #{$phone} {
      width: 100%;
      margin-left: 0;
    }
    a {
        display: block;
        padding-top: 45px;
        border-top: 1px solid #333;
        @include transition(color 0.2s, border-color 0.2s);
        @include play;
        @include font(#eff1f4, 24px, 38px);
        text-decoration: none;
        @media #{$tablet} {
          padding-top: 20px;
          font-size: 20px;
          line-height: 30px;
        }
        &:hover {
            color: $cYellow;
            border-top-color: $cYellow;
        }
    }
}
/*==================================================
                ARCHIVE PAGE
==================================================*/
.archive {
    h1 {
        color: #f2f2f2;
    }
}
.archive-filter-cnt {
}
.archive-timeline {
    padding-bottom: 20px;
    text-align: center;
}
.archive-year {
    display: inline-block;
    margin-left: 18px;
    @include ptsans;
    @include font(#808080, 20px, 1);
    @include transition(color 0.2s);
    &:hover {
        text-decoration: none;
        color: lighten(#808080, 15%);
    }
    &.active {
        color: $cYellow;
        &:hover {
            cursor: default;
        }
    }
    &:first-child {
        margin-left: 0;
    }
}
.archive-filter {
    border-top: 1px solid #333;
    padding-top: 20px;
    overflow: hidden;
    @media #{$tablet} {
      display: flex;
      flex-direction: column;
    }
}
.archive-search-cnt {
    width: 330px;
    float: left;
    @media #{$tablet} {
      float: none;
      width: auto;
      max-width: 90%;
    }
    @media #{$phone} {
      max-width: 100%;
    }
    &.materials-search {
        width: 100%;
        float: none;
        .search-input-cnt {
            border-color: #e6e6e6;
            input[type=text] {
                width: calc(100% - 88px);
                border-right-color: #e6e6e6;
                &::-webkit-input-placeholder {
                    color: #e6e6e6;
                }
                &:-moz-placeholder {
                    color: #e6e6e6;
                }
                &:-ms-input-placeholder {
                    color: #e6e6e6;
                }
            }
        }
    }
    .search-input-cnt {
        border-color: #333;
        input[type=text] {
            width: 237px;
            border-right-color: #333;
            @media #{$phone} {
              width: calc(100% - 88px);
            }
            &::-webkit-input-placeholder {
                color: #666;
            }
            &:-moz-placeholder {
                color: #666;
            }
            &:-ms-input-placeholder {
                color: #666;
            }
        }
    }
}
.archive-params-cnt {
    float: right;
    padding-top: 10px;
    @media #{$tablet} {
      float: none;
    }
    @media #{$phone} {
      display: flex;
      flex-direction: column;
    }
    &.materials-params {
        float: none;
        width: 100%;
        .archive-categories-list {
            float: none;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .category {
                color: #333;
                &.active {
                    color: #f5cb58;
                }
            }
        }
    }
}
.archive-date-selector {
    float: right;
    margin-left: 60px;
    @media #{$phone} {
      float: none;
      margin-left: 0;
      order: 2;
      margin-top: 20px;
    }
}
.archive-date-range {
    @include ptsans-b;
    @include font(#f2f2f2, 12px, 15px);
    @include transition(color 0.2s);
    &:hover {
        color: $cYellow;
        text-decoration: none;
    }
    &:before {
        display: inline-block;
        vertical-align: middle;
        color: $cYellow;
        margin-right: 10px;
        font-size: 16px;
        margin-top: -3px;
    }
}
.archive-categories-list {
    float: right;
    @include ptsans;
    @include font(#808080, 12px, 15px);
    @media #{$tablet} {
      float: none;
    }
    @media #{$phone} {
      order: 1;
      white-space: nowrap;
      overflow-x: scroll;
      padding-bottom: 5px;
    }
    .category {
        display: inline-block;
        @include ptsans-b;
        @include font(#f2f2f2, 12px, 15px);
        @include transition(color 0.2s);
        padding: 4px 5px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        margin-left: 16px;
        &:hover {
            color: $cYellow;
            text-decoration: none;
        }
        &.active {
            color: $cYellow;
            background: #333;
            &:hover {
                cursor: default;
            }
        }
    }
}
.archive-inner {
    position: relative;
}
.archive-list {
    padding-top: 20px;
    padding-bottom: 20px;
}
.archive-item {
    list-style-type: none;
    padding-top: 20px;
    padding-bottom: 40px;
    @media #{$tablet} {
      padding: 0;
      &+.archive-item {
        margin-top: 20px;
      }
    }
    a {
        display: block;
        text-decoration: none;
        overflow: hidden;
        @media #{$tablet} {
          display: flex;
        }
        @media #{$phone} {
          flex-direction: column;
        }
        &:hover {
            text-decoration: none;
            .archive-item-header {
                color: $cYellow;
            }
        }
    }
    &.empty {
        @include play-b;
        @include font(#f2f2f2, 18px, 150px);
        text-align: center;
        height: 150px;
        @media #{$tablet} {
          height: auto;
          padding: 100px 0;
          line-height: 1.3;
        }
        .lk & {
            color: #808080;
        }
    }
}
.archive-item-date {
    width: 120px;
    float: left;
    @include ptsans-b;
    @include font(#808080, 11px, 1);
    @media #{$tablet} {
      width: 70px;
      float: none;
      flex-shrink: 0;
    }
    @media #{$phone} {
      width: 100%;
      order: 1;
    }
}
.archive-item-content {
    width: 540px;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    @media #{$tablet} {
      width: calc(100% - 220px);
      float: none;
      box-sizing: border-box;
    }
    @media #{$phone} {
      width: 100%;
      padding: 20px 0;
      order: 3;
    }
}
.archive-item-category {
    @include ptsans;
    @include font(#e5e5e5, 11px, 15px);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
}
.archive-item-header {
    @include play-b;
    @include font(#f2f2f2, 18px, 30px);
    margin-bottom: 18px;
    @include transition(color 0.2s);
}
.archive-item-announce {
    @include ptsans;
    @include font(#f2f2f2, 12px, 24px);
}
.archive-item-image {
    width: 240px;
    float: right;
    @media #{$tablet} {
      float: none;
      width: 150px;
      flex-shrink: 0;
    }
    @media #{$phone} {
      width: 100%;
      order: 2;
      padding-bottom: 70%;
      position: relative;
    }
    .img-cnt {
        width: 240px;
        height: 190px;
        position: relative;
        overflow: hidden;
        @media #{$tablet} {
          width: 100%;
          height: 100px;
        }
        @media #{$phone} {
          position: absolute;
          width: 100%;
          height: 100%;
        }
    }
}
/*==================================================
                AWARDS PAGE
==================================================*/
.awards-list {
}
.award-item {
    border-top: 1px solid #ebebeb;
    background: #000;
    height: 260px;
    position: relative;
    overflow: hidden;
    &:first-child {
        border-top: none;
    }
}
%block {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.award-bg {
    @extend %block;
    &:after {
        content:'';
        @extend %block;
        background: rgba(0, 0, 0, 0.45);
    }
}
.award-link {
    @extend %block;
    line-height: 260px;
    text-align: center;
    &:before {
        content:'';
        @extend %block;
        background: #fff;
        @include transition(transform 0.2s);
    }
    &:hover {
        &:before {
            @include transform(scaleY(0));
        }
        .award-name {
            color: #f2f2f2;
        }
    }
}
.award-name {
    @include play;
    width: 960px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;
    @include font(#333333, 30px, 38px);
    position: relative;
    @include transition(color 0.2s);
    @media #{$tablet} {
      width: 100%;
      padding: 0 20px;
    }
}
/*==================================================
                AWARD INNER PAGE
==================================================*/
.award-form-cnt {
    border-top: 1px solid #e6e6e6;
    margin-top: 50px;
    @media #{$tablet} {
      margin-top: 30px;
      padding: 0 20px;
    }
}
.award-inner {
    width: 680px;
    margin-left: auto;
    margin-right: auto;
    @media #{$tablet} {
      width: 100%;
    }
    .header {
        @include ptsans-b;
        @include font(#333333, 13px, 1);
        margin-bottom: 10px;
    }
    textarea {
        height: 190px;
    }
}
.form-files-cnt {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
}
.add-file-text {
    @include ptsans-b;
    @include font(#333333, 11px, 1);
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    text-transform: uppercase;
}
.add-file-input-cnt {
    position: relative;
    overflow: hidden;
}
.add-file-input {
    &:hover {
        cursor: pointer;
    }
    &.noclick {
        &:hover {
            cursor: default;
        }
        .icon-remove {
            &:hover {
                cursor: pointer;
            }
        }
    }
    i {
        display: inline-block;
        font-size: 20px;
        &:before {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .fileinput {
        position: absolute;
        width: 0;
        height: 0;
        top: -10px;
        left: -10px;
    }
}
/*==================================================
                ITEM PAGE
==================================================*/
.item-content-cnt {
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    @include transition(background-color 0.2s);
    .black &{
        background: #1a1a1a;
    }
}
.item-zoom-cnt {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    background: #fff;
    @include transition(background-color 0.4s);
    overflow: hidden;
    .black &{
        background: #1a1a1a;
    }
    img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        @include transition(opacity 0.4s);
    }
    &.show {
        display: block;
        &:hover {
            cursor: url('/img/cur_close.png') 5 15, pointer;
        }
    }
    &.loading {
        background: #444 url('/img/preloader.gif') 50% 50% no-repeat;
        img {
            @include opacity(0);
        }
    }
}
.item-photo-cnt {
    position: absolute;
    left: 0;
    right: 0;
    top: 170px;
    bottom: 50px;
    @include transition(right 0.2s);
    .show-info & {
        right: 470px;
        @media #{$phone} {
          right: 100%;
        }
    }
    &.material-photo-cnt {
        bottom: 150px;
    }
    img {
      max-width: 100%;
    }
    @media #{$tablet} {
      right: 90px;
      left: 35px;
    }
    @media #{$phone} {
      top: 100px;
      right: 55px;
      left: 25px;
      bottom: 85px;
    }
}
.item-more-panel {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    text-align: center;
    @include transition(right 0.2s);
    .show-info & {
        right: 470px;
    }
    @media #{$tablet} {
      left: 20px;
      z-index: 10;
    }
}
.item-more-btn {
    display: inline-block;
    padding: 17px 60px;
    @include play;
    @include font(#333333, 16px, 1);
    letter-spacing: 2px;
    background-color: #f5cb58;
    text-transform: uppercase;
}
.item-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    @include opacity(0);
    visibility: hidden;
    @include transition(opacity 0.2s, visibility 0.2s);
    &.active {
        @include opacity(1);
        visibility: visible;
        @include transition(opacity 0.2s 0.2s, visibility 0.2s 0.2s);
    }
    &:before {
        content:'';
        height: 100%;
        width: 0;
        display: inline-block;
        vertical-align: middle;
        @media #{$tablet} {
          content: none;
        }
    }
    img {
        display: inline-block;
        vertical-align: middle;
        max-height: 100%;
        width: auto;
        &:hover {
            cursor: url('/img/cur_plus.png'), pointer;
        }
    }
    &.no-zoom {
        img {
            &:hover {
                cursor: default;
            }
        }
    }
}
.item-content-inner {
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 230px;
    padding-right: 90px;
    padding-bottom: 60px;
    right: 0;
    top: 0;
    width: 470px;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    @include transform(translateX(490px));
    @include transition(transform 0.2s);
    @media #{$tablet} {
      padding-left: 20px;
      padding-top: 170px;
    }
    @media #{$phone} {
      width: 100%;
      padding-right: 50px;
      padding-top: 100px;
      padding-left: 25px;
      padding-bottom: 165px; 
    }
    .show-info & {
        @include transform(translateX(0));
    }
    .lt-ie9 & {
        right: -490px;
    }
}
.more-popup-inner {
    min-width: 700px;
    max-width: 700px;
    min-height: 500px;
    max-height: 540px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    @media #{$tablet} {
      min-width: 600px;
      max-width: 600px;
    }
    @media #{$phone} {
      min-width: 240px;
      max-width: 320px;
      min-height: 300px;
      max-height: 300px;
      padding: 20px 0 0 0;
    }
    iframe {
        min-height: 500px;
        width: 100%;
        height: 100%;
        @media #{$phone} {
          min-height: 300px;
        }
    }
    img {
        width: 100%;
    }
}
.open-btn {
    width: 40px;
    height: 40px;
    background: rgba(0,0,0,0.75);
    position: absolute;
    top: 45px;
    right: 55px;
    &:before {
      content: "";
      width: 32px;
      height: 32px;
      position: absolute;
      background: url('/img/icon_open.png') 0 0 no-repeat;
      top: 50%;
      left: 50%;
      margin: -16px 0 0 -16px;
  }
}
.fancy-gallery .fancybox-bg {
    opacity: 1;
}
.fbx-close-cnt-coin.fbx-close-cnt {
    right: -54px;
    top: 0;
    left: auto;
}
.coin-descr {
    margin-bottom: 25px;
    @include ptsans;
    font-size: 16px;
    color: #333333;
    line-height: 30px;
    @media #{$phone} {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .black & {
        color: #ccc;
    }
}
.coin-info {
    .row {
        overflow: hidden;
        margin-bottom: 25px;
        @media #{$phone} {
          margin-bottom: 10px;
        }
    }
    aside {
        float: left;
        width: 120px;
    }
    .info-cnt {
        margin-left: 140px;
        p {
            @include ptsans;
            @include font(#333333, 14px, 24px);
            margin-bottom: 14px;
            @media #{$tablet} {
              margin-bottom: 10px;
            }
            .black & {
                @include font(#ccc, 14px, 24px);
            }
            &.big {
                font-size: 16px;
                line-height: 26px;
            }
            a {
                color: #333333;
                border-bottom: 1px solid #e6e6e6;
                .black & {
                    color: #ccc;
                }
                &:hover {
                    text-decoration: none;
                    border-bottom: none;
                }
            }
        }
    }
    .info-header {
        @include ptsans-b;
        @include font(#333333, 12px, 20px);
        text-transform: uppercase;
        .black & {
            color: #ccc;
        }
    }
}
.side-actions-cnt {
    position: absolute;
    width: 50px;
    top: 230px;
    right: 40px;
    @media #{$phone} {
      right: 5px;
      top: 170px;
    }
}
.coin-thumbs-list {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 15px;
    @include transition(opacity 0.2s, visibility 0.2s);
    .show-info & {
        @include opacity(0);
        visibility: hidden;
    }
}
.coin-thumb {
    list-style-type: none;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-position: 50% 50%;
    border:2px solid #fff;
    margin-bottom: 5px;
    @include transition(border-color 0.2s);
    &:hover {
        cursor: pointer;
    }
    &.active {
        border:2px solid #f2cb58;
        &:hover {
            cursor: default;
        }
    }
}
.side-action {
    width: 50px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    .info-img {
        display: inline-block;
        .show-info & {
            display: none;
        }
    }
    .close-img {
        display: none;
        .show-info & {
            display: inline-block;
        }
    }
}
.bg-switcher {
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -17px;
    width: 15px;
    height: 35px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    border:1px solid #ccc;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    @media #{$phone} {
      left: 5px;
    }
    @include transition(border-color 0.2s, background-color 0.2s);
    &:before {
        content:'';
        position: absolute;
        left: 3px;
        top: 4px;
        width: 7px;
        height: 7px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #333;
        @include transition(all 0.2s);
    }
    &:after {
        content:'';
        position: absolute;
        width: 1px;
        height: 11px;
        background: #ccc;
        left: 6px;
        top: 15px;
        @include transition(all 0.2s);
    }
    &:hover {
        border-color: $cYellow;
        background: $cYellow;
        cursor: pointer;
        &:before {
            background: #fff;
        }
        &:after {
            background: #fff;
        }
    }
    .black & {
        background: #1a1a1a;
        &:before {
            width: 1px;
            height: 11px;
            background: #fff;
            left: 6px;
            top: 6px;
        }
        &:after {
            width: 7px;
            height: 7px;
            background: #fff;
            left: 3px;
            top: 21px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }
    }
}
/*==================================================
                ABOUT PAGE
==================================================*/
/*==================================================
                SITE FOOTER
==================================================*/
#footer {
    position: absolute;
    bottom: 0;
    height: 45px;
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 20px;
    @include play;
    @include font(#858585, 10px, 1);
    text-transform: uppercase;
    letter-spacing: 1px;
    left: 0;
    right: 0;
    @include transition(border-color 0.2s);
    @media #{$tablet} {
      height: 61px;
      padding-bottom: 20px;
      padding-right: 20px;
    }
    @media #{$phone} {
      height: 81px;
    }
    .index & {
        display: none;
    }
    .black & {
        border-top-color: #333;
    }
}
.popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    @include opacity(0);
    visibility: hidden;
    @include transform(rotateX(-80deg));
    background: url('/img/bg_popup.jpg') 50% 50% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    z-index: 100;
    left: 0;
    top: 0;
    @include transform-origin(0,0);
    @include transition(opacity 0.2s, transform 0.3s cubic-bezier(0.165, 0.775, 0.430, 1.005), visibility 0.3s);
    @media #{$tablet} {
      overflow-y: scroll;
    }
    &.visible {
        @include opacity(1);
        visibility: visible;
        @include transform(rotateX(0deg));
    }
    .popup-inner {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    .popup-close-cnt {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
    }
    .popup-close {
        @include play-b;
        @include font(#fff, 11px, 1);
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        &:hover {
            text-decoration: none;
        }
        i {
            display: inline-block;
            color: #fff;
            font-size: 12px;
            margin-bottom: 15px;
        }
    }
    .popup-content {
        text-align: center;
    }
    .auth-form-cnt {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 250px;
        form {
        }
        input[type=text], input[type=password] {
            display: block;
            background: none;
            text-align: center;
            width: 300px;
            border:none;
            border-bottom: 1px solid #fff;
            @include play;
            @include transition(border-color 0.2s);
            font-size: 14px;
            color: #fff;
            letter-spacing: 4px;
            text-transform: uppercase;
            padding-top: 12px;
            padding-bottom: 12px;
            &:focus {
                outline: none;
                border-bottom: 1px solid $cYellow;
            }
            &::-webkit-input-placeholder {
                @include play;
                letter-spacing: 4px;
                color: #ccc;
            }
            &:-moz-placeholder {
                @include play;
                letter-spacing: 4px;
                color: #ccc;
            }
            &:-ms-input-placeholder {
                color: #ccc;
                letter-spacing: 4px;
                @include play;
            }
        }
        input[type=submit] {
            border:1px solid #fff;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            @include play;
            @include font(#fff, 11px, 1);
            @include transition(color 0.2s, background-color 0.2s, border-color 0.2s);
            text-transform: uppercase;
            letter-spacing: 4px;
            &:focus {
                outline:none;
                border-color: $cYellow;
            }
            &:hover {
                background: #fff;
                color: #41281b;
                border-color: $cWhite;
            }
        }
        fieldset {
            position: relative;
            width: 300px;
            margin-bottom: 15px;
            border-top:1px solid transparent;
            @include transition(border-color 0.2s);
            &:before, &:after {
                content:'';
                position: absolute;
                bottom: 0;
                height: 5px;
                width: 1px;
                background: #fff;
                @include transition(background-color 0.2s, height 0.2s);
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
            &.focus {
                border-top: 1px solid $cYellow;
                @include transition(border-color 0.2s 0.2s);
                &:before, &:after {
                    background: $cYellow;
                    height: 100%;
                }
            }
            &.form-submit-cnt {
                margin-top: 35px;
                &:before, &:after {
                    display: none;
                }
            }
        }
        .btn-secondary {
            color: #fff;
            width: inherit;
            &:hover {
                color: $cYellow;
            }
        }
    }
    .popup-menu-cnt {
        width: 820px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 170px;
        overflow: hidden;
        @media #{$tablet} {
          width: 90%;
        }
        @media #{$phone} {
          padding-top: 90px;
        }
    }
    .common-menu-cnt {
        width: 560px;
        @media #{$tablet} {
          float: none;
          width: 100%;
          overflow: hidden;
        }
    }
    .personal-menu-cnt {
        width: 190px;
        @media #{$tablet} {
          float: none;
          width: 100%;
          margin-top: 20px;
          overflow: hidden;
        }
    }
    .popup-menu-header {
        display: block;
        @include play;
        @include font(#fff, 12px, 1);
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding-bottom: 10px;
        position: relative;
        margin-bottom: 35px;
        @media #{$phone} {
          margin-bottom: 25px;
        }
        &:after {
            content:'';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #fff;
            @include opacity(0.2);
        }
        + ul {
            margin-left: 0;
        }
    }
    .popup-menu {
        width: auto;
        float: left;
        margin-left: 30px;
        margin-top: -4px;
        @media #{$phone} {
          float: none;
          margin-left: 0;
        }
        li {
            list-style: none;
            margin-bottom: 32px;
            text-align: left;
            @media #{$phone} {
              margin-bottom: 15px;
            }
        }
        a {
            @include play-b;
            @include font(#fff, 16px, 1);
            display: inline-block;
            padding: 4px 8px;
            position: relative;
            @media #{$phone} {
              font-size: 14px;
            }
            &:before {
              content: "";
              width: 100%;
              height: 100%;
              border:1px solid $cYellow;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
            }
            &:hover {
                text-decoration: none;
                &:before {
                  opacity: 1;
                }
            }
            &.active {
                &:before {
                  opacity: 1;
                }
            }
        }
    }
    .popup-menu-text {
        @include play;
        @include font(#fff, 14px, 24px);
        text-align: left;
        a {
            text-decoration: none;
            border-bottom: 1px solid #c1b1a7;
            color: #fff;
        }
    }
}
#auth-error {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #FBD7E1;
    padding: 20px;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
    p {
        @include ptsans;
        color: #fff;
    }
}
@import "components/forms";
@import "components/popups";
@import "components/chosen";
@import "components/datepicker";
@import "components/scrolls";
@import "components/validation";
@import "helpers";
@import "fallback";
/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
//@media only screen and (min-width: 35em) {
//    /* Style adjustments for viewports that meet the condition */
//}
//
//(-o-min-device-pixel-ratio: 5/4),
//(-webkit-min-device-pixel-ratio: 1.25),
//(min-resolution: 120dpi) {
//    /* Style adjustments for high resolution devices */
//}
/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group; /* h5bp.com/t */
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    @page {
        margin: 0.5cm;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}
.published-inner-date {
    @include ptsans;
    @include font(#f2f2f2, 10px, 1);
    text-align: center;
}
.published-inner-text {
    padding-top: 50px;
    p {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        @include ptsans;
        @include font(#333333, 22px, 36px);
        margin-bottom: 40px;
        @media #{$tablet} {
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        &.first {
            &::first-letter {
                font-size: 64px;
                float: left;
                line-height: 78px;
                margin-right: 12px;
                @media #{$tablet} {
                  font-size: 54px;
                  line-height: 66px;
                  margin-right: 10px;
                }
            }
        }
    }
    .image {
        position: relative;
        margin: 50px 0;
        &:before {
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            background-color: #000;
            opacity: 0.5;
            top: 0;
            left: 0;
            bottom: 0;
        }
        img {
            width: auto;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}
.content-lr {
    position: relative;
    padding-top: 50px;
    @media #{$tablet} {
      display: flex;
    }
    @media #{$phone} {
      flex-direction: column;
    }
    &__menu {
        display: inline-block;
        vertical-align: top;
        width: 190px;
        @media #{$tablet} {
          flex-shrink: 0;
        }
        @media #{$phone} {
          width: 100%;
        }
        &__item {
            display: block;
            @include play;
            @include font(#333, 20px, 20px);
            text-transform: uppercase;
            margin-bottom: 25px;
            @media #{$phone} {
              margin-bottom: 20px;
            }
            span {
                display: inline-block;
                &:after {
                    display: block;
                    content: '';
                    width: 0;
                    height: 4px;
                    background-color: #f5cb58;
                    transition: .3s;
                }
            }
            &.active,
            &:hover {
                span {
                    &:after {
                        width: 100%;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    &__content {
        display: inline-block;
        vertical-align: top;
        width: 735px;
        margin-left: 30px;
        @media #{$tablet} {
          width: calc(100% - 190px);
          margin-left: 0;
          padding-left: 30px;
          box-sizing: border-box;
        }
        @media #{$phone} {
          width: 100%;
          padding-left: 0;
          padding-top: 20px;
        }
    }
}
.news-list {
    &__item {
        margin-bottom: 30px;
        @media #{$tablet} {
          display: flex;
        }
        @media #{$phone} {
          flex-direction: column;
          margin-bottom: 20px;
        }
    }
    &__left {
        display: inline-block;
        vertical-align: top;
        width: 220px;
        @media #{$tablet} {
          width: 180px;
          flex-shrink: 0;
        }
        @media #{$phone} {
          width: 100%;
        }
    }
    &__right {
        display: inline-block;
        vertical-align: top;
        width: 480px;
        margin-left: 30px;
        @media #{$tablet} {
          width: calc(100% - 180px);
        }
        @media #{$phone} {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }
    }
    &__image {
        margin-bottom: 10px;
        img {
          width: 100%;
        }
        @media #{$phone} {
          margin-bottom: 20px;
        }
    }
    &__date {
        @include ptsans-b;
        @include font(#808080, 12px, 12px);
    }
    &__title {
        @include play-b;
        @include font(#333, 20px, 20px);
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    &__text {
        @include ptsans;
        @include font(#333333, 14px, 24px);
        margin-bottom: 15px;
    }
    &__link {
        display: inline-block;
        @include ptsans;
        @include font(#333333, 10px, 26px);
        text-transform: uppercase;
        letter-spacing: 0.83px;
        border-bottom: 1px solid #333;
        &:hover {
            text-decoration: none;
            border: none;
        }
    }
}
.news-video {
    text-align: center;
    margin: 40px 0;
}
.conference-tab {
    .tabs-list {
        display: inline-block;
        margin-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
        @media #{$tablet} {
          display: flex;
          overflow-x: scroll;
          padding-bottom: 10px;
          white-space: nowrap;
        }
        li {
            margin-left: 15px;
            a {
                @include ptsans-b;
                @include font(#333, 10px, 10px);
                letter-spacing: 0.9px;
                text-transform: uppercase;
                padding-bottom: 5px;
                border-bottom: 2px solid transparent;
            }
            &.active,
            &:hover {
                a {
                    border-bottom: 2px solid;
                    border-bottom-color: #f5cb58;
                }
            }
        }
    }
}
.conference-tab-forms {
    margin-top: 35px;
    &__items {
        overflow: hidden;
        margin-bottom: 35px;
    }
    .conference-tab-item {
        float: left;
        width: 207px;
        height: 46px;
        box-sizing: border-box;
        @include ptsans;
        @include font(#333333, 14px, 44px);
        text-align: center;
        text-transform: uppercase;
        border: 1px solid #e5e5e5;
        cursor: pointer;
        &:first-child {
            border-right: 1px solid transparent;
            &.active {
                border-right: 1px solid #f5cb58;
            }
        }
        &:last-child {
            border-left: 1px solid transparent;
            &.active {
                border-left: 1px solid #f5cb58;
            }
        }
        &.active {
            border-color: #f5cb58;
        }
    }
    .conference-tab-block {
        display: none;
        margin: 0;
        &.active {
            display: block;
        }
    }
}
.attach-file-text {
    @include ptsans;
    @include font(#333333, 14px, 30px);
    text-transform: uppercase;
    margin-bottom: 10px;
}
.map {
    position: relative;
    width: 100%;
    height: 339px;
    margin-top: 35px;
}
.m-t_150 {
    margin-top: 150px;
}
.materials {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: 40px 0;
    @media #{$tablet} {
      margin: 30px 0;
    }
    &__item {
        width: calc(100% / 3);
        margin-bottom: 10px;
        border: 1px solid #ffffff;
        @include transition(border-color 0.2s);
        box-sizing: border-box;
        @media #{$tablet} {
          width: calc(100% / 2);
        }
        @media #{$phone} {
          width: 100%;
          margin-bottom: 20px;
        }
        &:hover {
            border-color: #f5cb58;
        }
    }
    &__link {
        display: block;
        width: 100%;
        height: 100%;
        &:hover {
            text-decoration: none;
        }
    }
    &__picture {
        width: 100%;
        height: 230px;
        display: flex;    
        align-items: center;
        justify-content: center;
        padding: 10px;
        box-sizing: border-box;
        @media #{$tablet} {
          padding: 0 10px;
          height: 170px;
        }
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
    &__info {
        @include ptsans-b;
        @include font(#333, 16px, 22px);
        padding: 10px 10px 35px 10px;
        box-sizing: border-box;
    }
    &__date {
        color: #c1c1c1;
    }
}