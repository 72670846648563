//.date-picker
//{
//    width:170px;
//    height:25px;
//    padding:0;
//    border:0;
//    padding-left:10px;
//    @include ptsans;
//    @include font(#303030, 13px, 25px);
//    cursor:pointer;
//    color:#303030;
//    position:relative;
//    z-index:2;
//}
.date-picker-wrapper
{
    position:absolute;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    z-index:1;
    border:none;
    background-color:#333;
    width:575px;
    padding: 0 10px;
    @include ptsans;
    @include font(#f2f2f2, 10px, 22px);
    box-shadow:0 15px 30px rgba(0,0,0,0.2);
    @media #{$phone} {
      width: 285px;
      padding: 0;
    }
}


.date-picker-wrapper.single-date {
    width:auto;
}
.date-picker-wrapper.no-shortcuts { padding-bottom:0;}
.date-picker-wrapper .footer
{
}
.date-picker-wrapper b
{
    font-weight: normal;
    font-size: 14px;
    color:#2f3338;
    display: none;
}
.date-picker-wrapper a
{
    color: rgb(107, 180, 214);
    text-decoration:underline;
}
.date-picker-wrapper .month-wrapper
{
    border:none;
    border-radius:0;
    background-color:#333;
    padding:15px 10px;
    cursor:default;
    position:relative;
    _overflow:hidden;
}
.date-picker-wrapper .month-wrapper table
{
    width:265px;
    float:left;
}

.date-picker-wrapper .month-wrapper table.month2
{
    width:265px;
    float:right;
}
.date-picker-wrapper .month-wrapper table th,
.date-picker-wrapper .month-wrapper table td
{
    vertical-align:middle;
    text-align:center;
    font-size: 13px;
    line-height:14px;
    margin : 0px;
    padding : 0px;
}

.date-picker-wrapper .month-wrapper table th.month-name {
    @include ptsans;
    font-weight: normal;
    @include font(#f2f2f2, 14px, 1);
    text-transform: capitalize;
}
.date-picker-wrapper .month-wrapper table .day
{
    height:25px;
    line-height:25px;
    font-size:12px;
    margin-bottom:4px;
    color:#f2f2f2;
    cursor:default;
    &:hover {
        background: #4d4d4d;
    }
}
.date-picker-wrapper .month-wrapper table div.day.lastMonth,
.date-picker-wrapper .month-wrapper table div.day.nextMonth
{
    display: none;
    color:#999;
    cursor:default;
}
.date-picker-wrapper .month-wrapper table div.day.lastMonth.checked,
.date-picker-wrapper .month-wrapper table div.day.nextMonth.checked {
    color: #ddd;
}
.date-picker-wrapper .month-wrapper table .day.checked
{
    background-color: $cYellow;
    color: #333;
}
.date-picker-wrapper .month-wrapper table .week-name
{
    height:20px;
    line-height:20px;
    font-weight:100;
}

.date-picker-wrapper .month-wrapper table .week-name th {
    @include ptsans;
    @include font(#f2f2f2, 10px, 1);
    font-weight: normal;
    text-transform: capitalize;
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip { cursor:help !important;}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid
{
    color:#f2f2f2;
    cursor:pointer;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid.checked {
    color: #333;
}

.date-picker-wrapper .month-wrapper table .day.real-today {
    background-color: #404040;
    border-bottom: 1px solid #666;
    &:hover {
        background-color: #4d4d4d;
    }
}
.date-picker-wrapper .month-wrapper table .day.real-today.checked { background-color: $cYellow; }


.date-picker-wrapper table .caption
{
    height:40px;
}
.date-picker-wrapper table .caption .next,
.date-picker-wrapper table .caption .prev
{
    display: inline-block;
    width: 40px;
    height: 20px;
    padding:0;
    text-align: center;
    cursor:pointer;
    font-family: "nmk" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: $cYellow;
}
.date-picker-wrapper table .caption .next {
    &:before {
        content:'k';
    }
}

.date-picker-wrapper table .caption .prev {
    &:before {
        content:'l';
    }
}
.date-picker-wrapper table .caption .next:hover,
.date-picker-wrapper table .caption .prev:hover
{
    background-color: #333;
//    @include opacity(1);
}

.date-picker-wrapper .gap
{
    position:absolute;
    display:none;
    top:0px;
    left:282px;
    z-index: 1;
    width:15px;
    height: 100%;
    background-color:transparent;
    font-size:0;
    line-height:0;
}
.date-picker-wrapper .gap .gap-lines { height: 100%; overflow:hidden; }
.date-picker-wrapper .gap .gap-line { height:15px;width:15px; position:relative;  }
.date-picker-wrapper .gap .gap-line .gap-1 { z-index:1; height:0; border-left:8px solid transparent; border-top:8px solid #666;border-bottom:8px solid #666; }
.date-picker-wrapper .gap .gap-line .gap-2 { position:absolute; right:0; top:0px; z-index:2; height:0; border-left:8px solid transparent; border-top:8px solid #333; }
.date-picker-wrapper .gap .gap-line .gap-3 { position:absolute; right:0; top:8px; z-index:2; height:0; border-left:8px solid transparent; border-bottom:8px solid #333; }
.date-picker-wrapper .gap .gap-top-mask { width: 6px; height:1px; position:absolute; top: -1px; left: 1px; background-color: transparent; z-index:3; }
.date-picker-wrapper .gap .gap-bottom-mask { width: 6px; height:1px; position:absolute; bottom: -1px; left: 7px; background-color: transparent; z-index:3; }

.date-picker-wrapper .selected-days
{
    display:none;
}
.date-picker-wrapper .drp_top-bar
{
    display: none;
    line-height:40px;
    height:40px;
    position:relative;
}
.date-picker-wrapper .drp_top-bar .error-top { display:none; }
.date-picker-wrapper .drp_top-bar .normal-top { display:none; }
.date-picker-wrapper .drp_top-bar .default-top { display:block; }

.date-picker-wrapper .drp_top-bar.error .default-top { display:none; }
.date-picker-wrapper .drp_top-bar.error .error-top { display:block; color:red; }

.date-picker-wrapper .drp_top-bar.normal .default-top { display:none; }
.date-picker-wrapper .drp_top-bar.normal .normal-top { display:block; }

.date-picker-wrapper .buttons-cnt {
    position:absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    text-align: center;
    height: 30px;
    line-height: 30px;
}

.date-picker-wrapper .apply-btn, .date-picker-wrapper .close-btn {
    display: inline-block;
    vertical-align: middle;
}

.date-picker-wrapper .close-btn {
    margin-left: 15px;
    @include ptsans;
    @include font(#454a52, 12px, 1);
    text-decoration: underline;
}


.date-picker-wrapper .apply-btn
{
    padding:8px 30px;
    margin:0;
    font-size:11px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor:pointer;
    color: #fff;
    border: none;
    background: #f15b5a;
    outline:none;
}


.date-picker-wrapper .apply-btn.disabled
{
    cursor: pointer;
    background: #e6e6e6;
    color: #cdd0d1;
    border: none;
}

.date-picker-wrapper {
    .custom-shortcut {
        padding-left: 20px;
        padding-bottom: 20px;
        display: inline-block;
        a {
            color: $cYellow;
        }
    }
}
/*time styling*/
.time {
    position: relative;
}
.time input[type=range] {
    vertical-align: middle;
}
.time1, .time2 {
    width: 180px;
    padding: 0 5px;
    text-align: center;
}
.time1 {
    float: left;
}
.time2 {
    float: right;
}
.hour, .minute {
    text-align: right;
}
.hide {
    display: none;
}
