ul.pagination {
    display: block;
    text-align: center;
    border-top: 1px solid #e6e6e6;
    overflow: hidden;
    margin-bottom: 30px;

    &.right {
        float: right;

    }

    &.left {
        float: left;
    }

    li {
        display: inline-block;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: 5px;
        margin-right: 5px;

        &:after {
            display: none;
        }

        a {
            display: inline-block;
            padding: 15px 10px;
            @include ptsans-b;
            @include font(#333, 16px, 1);
            @include transition(color 0.2s, background-image 0.2s);
            position: relative;
            &:after {
                content:'';
                position: absolute;
                width: 100%;
                height: 1px;
                background: #333;
                top: -1px;
                left: 0;
            }

            &:hover {
                border: none;
                text-decoration: none;
            }
            &[href] {
                @include ptsans;
                &:hover {
                    color: $cYellow;
                }
                &:after {
                    display: none;
                }
            }
        }
        &.item-first {
            float: left;
            a {
                width: 10px;
                padding-left: 0;
                &[href] {
                    &:hover {

                    }
                }
            }
        }
        &.item-last {
            float: right;
            a {
                width: 10px;
                padding-right: 0;
                &[href] {
                    &:hover {

                    }
                }
            }
        }
    }
}

