   // CSS Transitions
// Currently only works in Webkit.
//
// * expected in CSS3, FireFox 3.6/7 and Opera Presto 2.3
// * We'll be prepared.
//
// Including this submodule sets following defaults for the mixins:
//
//     $default-transition-property : all
//     $default-transition-duration : 1s
//     $default-transition-function : false
//     $default-transition-delay    : false
//
// Override them if you like. Timing-function and delay are set to false for browser defaults (ease, 0s).

$default-transition-property: all !default;

$default-transition-duration: 1s !default;

$default-transition-function: false !default;

$default-transition-delay: false !default;

$transitionable-prefixed-values: transform, transform-origin !default;

// One or more properties to transition
//
// * for multiple, use a comma-delimited list
// * also accepts "all" or "none"

@mixin transition-property($property-1: $default-transition-property,
  $property-2 : false,
  $property-3 : false,
  $property-4 : false,
  $property-5 : false,
  $property-6 : false,
  $property-7 : false,
  $property-8 : false,
  $property-9 : false,
  $property-10: false
) {
@if type-of($property-1) == string { $property-1: unquote($property-1); }
  $properties: ($property-1, $property-2, $property-3, $property-4, $property-5, $property-6, $property-7, $property-8, $property-9, $property-10);
  @if $experimental-support-for-webkit    {       -webkit-transition-property : prefixed-for-transition(-webkit, $properties); }
  @if $experimental-support-for-mozilla   {          -moz-transition-property : prefixed-for-transition(-moz,    $properties); }
  @if $experimental-support-for-opera     {            -o-transition-property : prefixed-for-transition(-o,      $properties); }
                                                          transition-property : $properties;
}

// One or more durations in seconds
//
// * for multiple, use a comma-delimited list
// * these durations will affect the properties in the same list position

@mixin transition-duration($duration-1: $default-transition-duration,
  $duration-2 : false,
  $duration-3 : false,
  $duration-4 : false,
  $duration-5 : false,
  $duration-6 : false,
  $duration-7 : false,
  $duration-8 : false,
  $duration-9 : false,
  $duration-10: false
) {
  @if type-of($duration-1) == string { $duration-1: unquote($duration-1); }
  $durations: ($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10);
  @include experimental(transition-duration, $durations,
    -moz, -webkit, -o, not -ms, not -khtml, official
  );
}

// One or more timing functions
//
// * [ ease | linear | ease-in | ease-out | ease-in-out | cubic-bezier(x1, y1, x2, y2)]
// * For multiple, use a comma-delimited list
// * These functions will effect the properties in the same list position

@mixin transition-timing-function($function-1: $default-transition-function,
  $function-2 : false,
  $function-3 : false,
  $function-4 : false,
  $function-5 : false,
  $function-6 : false,
  $function-7 : false,
  $function-8 : false,
  $function-9 : false,
  $function-10: false
) {
  $function-1: unquote($function-1);
  $functions: ($function-1, $function-2, $function-3, $function-4, $function-5, $function-6, $function-7, $function-8, $function-9, $function-10);
  @include experimental(transition-timing-function, $functions,
    -moz, -webkit, -o, not -ms, not -khtml, official
  );
}

// One or more transition-delays in seconds
//
// * for multiple, use a comma-delimited list
// * these delays will effect the properties in the same list position

@mixin transition-delay($delay-1: $default-transition-delay,
  $delay-2 : false,
  $delay-3 : false,
  $delay-4 : false,
  $delay-5 : false,
  $delay-6 : false,
  $delay-7 : false,
  $delay-8 : false,
  $delay-9 : false,
  $delay-10: false
) {
  @if type-of($delay-1) == string { $delay-1: unquote($delay-1); }
  $delays: ($delay-1, $delay-2, $delay-3, $delay-4, $delay-5, $delay-6, $delay-7, $delay-8, $delay-9, $delay-10);
  @include experimental(transition-delay, $delays,
    -moz, -webkit, -o, not -ms, not -khtml, official
  );
}

// Transition all-in-one shorthand

@mixin single-transition(
  $property: $default-transition-property,
  $duration: $default-transition-duration,
  $function: $default-transition-function,
  $delay: $default-transition-delay
) {
  @include transition(($property $duration $function $delay));
}

@mixin transition(
  $transition-1 : default,
  $transition-2 : false,
  $transition-3 : false,
  $transition-4 : false,
  $transition-5 : false,
  $transition-6 : false,
  $transition-7 : false,
  $transition-8 : false,
  $transition-9 : false,
  $transition-10: false
) {
  @if $transition-1 == default {
    $transition-1 : ($default-transition-property $default-transition-duration $default-transition-function $default-transition-delay);
  }
  $transitions: false;
  @if type-of($transition-1) == list and type-of(nth($transition-1,1)) == list {
    $transitions: join($transition-1, ($transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10), comma);
  } @else {
    $transitions : ($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10);
  }
  $delays: comma-list();
  $has-delays: false;
  $webkit-value: comma-list();
  $moz-value: comma-list();
  $o-value: comma-list();

  // This block can be made considerably simpler at the point in time that
  // we no longer need to deal with the differences in how delays are treated.
  @each $transition in $transitions {
    // Extract the values from the list
    // (this would be cleaner if nth took a 3rd argument to provide a default value).
    $property: nth($transition, 1);
    $duration: false;
    $timing-function: false;
    $delay: false;
    @if length($transition) > 1 { $duration:        nth($transition, 2); }
    @if length($transition) > 2 { $timing-function: nth($transition, 3); }
    @if length($transition) > 3 { $delay:           nth($transition, 4); $has-delays: true; }

    // If a delay is provided without a timing function
    @if is-time($timing-function) and not $delay { $delay: $timing-function; $timing-function: false; $has-delays: true; }

    // Keep a list of delays in case one is specified
    $delays: append($delays, if($delay, $delay, 0s));

    $webkit-value: append($webkit-value, (prefixed-for-transition(-webkit, $property) $duration $timing-function));
       $moz-value: append(   $moz-value, (prefixed-for-transition(   -moz, $property) $duration $timing-function $delay));
         $o-value: append(     $o-value, (prefixed-for-transition(     -o, $property) $duration $timing-function $delay));
  }

  @if $experimental-support-for-webkit    {       -webkit-transition : $webkit-value;
    // old webkit doesn't support the delay parameter in the shorthand so we progressively enhance it.
    @if $has-delays                       { -webkit-transition-delay : $delays;       } }
  @if $experimental-support-for-mozilla   {          -moz-transition : $moz-value;    }
  @if $experimental-support-for-opera     {            -o-transition : $o-value;      }
                                                          transition : $transitions;
}

// coerce a list to be comma delimited or make a new, empty comma delimited list.
@function comma-list($list: ()) {
  @return join((), $list, comma);
}

// Returns `$property` with the given prefix if it is found in `$transitionable-prefixed-values`.
@function prefixed-for-transition($prefix, $property) {
  @if type-of($property) == list {
    $new-list: comma-list();
    @each $v in $property {
      $new-list: append($new-list, prefixed-for-transition($prefix, $v));
    }
    @return $new-list;
  } @else {
    @if index($transitionable-prefixed-values, $property) {
      @return #{$prefix}-#{$property};
    } @else {
      @return $property;
    }
  }
}

// Checks if the value given is a unit of time.
@function is-time($value) {
  @if type-of($value) == number {
    @return not not index(s ms, unit($value));
  } @else {
    @return false;
  }
}
$experimental-support-for-mozilla : true !default;
$experimental-support-for-webkit : true !default;
$support-for-original-webkit-gradients : true !default;
$experimental-support-for-opera : true !default;
$experimental-support-for-microsoft : true !default;
$experimental-support-for-khtml : true !default;

@mixin experimental($property, $value,
  $moz      : $experimental-support-for-mozilla,
  $webkit   : $experimental-support-for-webkit,
  $o        : $experimental-support-for-opera,
  $ms       : $experimental-support-for-microsoft,
  $khtml    : $experimental-support-for-khtml,
  $official : true
) {
  @if $webkit  and $experimental-support-for-webkit    { -webkit-#{$property} : $value; }
  @if $khtml   and $experimental-support-for-khtml     {  -khtml-#{$property} : $value; }
  @if $moz     and $experimental-support-for-mozilla   {    -moz-#{$property} : $value; }
  @if $ms      and $experimental-support-for-microsoft {     -ms-#{$property} : $value; }
  @if $o       and $experimental-support-for-opera     {      -o-#{$property} : $value; }
  @if $official                                        {         #{$property} : $value; }
}
@mixin perspective($p) {
  @include experimental(perspective, $p,
    -moz, -webkit, -o, -ms, not -khtml, official
  );
}
// Provides cross-browser CSS opacity. Takes a number between 0 and 1 as the argument, e.g. 0.5 for 50% opacity.
//
//     @param $opacity
//         A number between 0 and 1, where 0 is transparent and 1 is opaque.

@mixin opacity($opacity) {
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 or $legacy-support-for-ie8 {
    filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
  }
  opacity: $opacity;
}

// Make an element completely transparent.
@mixin transparent { @include opacity(0); }

// Make an element completely opaque.
@mixin opaque { @include opacity(1); }
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: true;
$legacy-support-for-ie8: true;
$legacy-support-for-mozilla: false;
$default-border-radius: 5px !default;

// Round all corners by a specific amount, defaults to value of `$default-border-radius`.
//
// When two values are passed, the first is the horizontal radius
// and the second is the vertical radius.
//
// Note: webkit does not support shorthand syntax for several corners at once.
// So in the case where you pass several values only the first will be passed to webkit.
//
// Examples:
//
//     .simple   { @include border-radius(4px, 4px); }
//     .compound { @include border-radius(2px 5px, 3px 6px); }
//     .crazy    { @include border-radius(1px 3px 5px 7px, 2px 4px 6px 8px)}
//
// Which generates:
//
//    .simple {
//      -webkit-border-radius: 4px 4px;
//      -moz-border-radius: 4px / 4px;
//      -khtml-border-radius: 4px / 4px;
//      border-radius: 4px / 4px; }
//    
//    .compound {
//      -webkit-border-radius: 2px 3px;
//      -moz-border-radius: 2px 5px / 3px 6px;
//      -khtml-border-radius: 2px 5px / 3px 6px;
//      border-radius: 2px 5px / 3px 6px; }
//    
//    .crazy {
//      -webkit-border-radius: 1px 2px;
//      -moz-border-radius: 1px 3px 5px 7px / 2px 4px 6px 8px;
//      -khtml-border-radius: 1px 3px 5px 7px / 2px 4px 6px 8px;
//      border-radius: 1px 3px 5px 7px / 2px 4px 6px 8px; }

@mixin border-radius($radius: $default-border-radius, $vertical-radius: false) {

  @if $vertical-radius {
    // Webkit doesn't understand the official shorthand syntax for specifying
    // a vertical radius unless so in case there's several we only take the first.
    @include experimental(border-radius, first-value-of($radius) first-value-of($vertical-radius),
      not -moz,
      -webkit,
      not -o,
      not -ms,
      not -khtml,
      not official
    );
    @include experimental("border-radius", $radius unquote("/") $vertical-radius,
      -moz,
      not -webkit,
      not -o,
      not -ms,
      -khtml,
      official
    );
  }
  @else {
    @include experimental(border-radius, $radius);
  }
}

// Round radius at position by amount.
//
// * legal values for `$vert`: `top`, `bottom`
// * legal values for `$horz`: `left`, `right`

@mixin border-corner-radius($vert, $horz, $radius: $default-border-radius) {
  // Support for mozilla's syntax for specifying a corner
  @include experimental("border-radius-#{$vert}#{$horz}", $radius,
    -moz,
    not -webkit,
    not -o,
    not -ms,
    not -khtml,
    not official
  );
  @include experimental("border-#{$vert}-#{$horz}-radius", $radius,
    not -moz,
    -webkit,
    not -o,
    not -ms,
    -khtml,
    official
  );
  
}

// Round top-left corner only

@mixin border-top-left-radius($radius: $default-border-radius) {
  @include border-corner-radius(top, left, $radius); }

// Round top-right corner only

@mixin border-top-right-radius($radius: $default-border-radius) {
  @include border-corner-radius(top, right, $radius); }

// Round bottom-left corner only

@mixin border-bottom-left-radius($radius: $default-border-radius) {
  @include border-corner-radius(bottom, left, $radius); }

// Round bottom-right corner only

@mixin border-bottom-right-radius($radius: $default-border-radius) {
  @include border-corner-radius(bottom, right, $radius); }

// Round both top corners by amount
@mixin border-top-radius($radius: $default-border-radius) {
  @include border-top-left-radius($radius);
  @include border-top-right-radius($radius); }

// Round both right corners by amount
@mixin border-right-radius($radius: $default-border-radius) {
  @include border-top-right-radius($radius);
  @include border-bottom-right-radius($radius); }

// Round both bottom corners by amount
@mixin border-bottom-radius($radius: $default-border-radius) {
  @include border-bottom-left-radius($radius);
  @include border-bottom-right-radius($radius); }

// Round both left corners by amount
@mixin border-left-radius($radius: $default-border-radius) {
  @include border-top-left-radius($radius);
  @include border-bottom-left-radius($radius); }

// @doc off
// Note ----------------------------------------------------------------------
// Safari, Chrome, and Firefox all support 3D transforms. However,
// only in the most recent builds. You should also provide fallback 2d support for
// Opera and IE. IE10 is slated to have 3d enabled, but is currently unreleased.
// To make that easy, all 2D transforms include an browser-targeting toggle ($only3d)
// to switch between the two support lists. The toggle defaults to 'false' (2D),
// and also accepts 'true' (3D). Currently the lists are as follows:
// 2D: Mozilla, Webkit, Opera, Official
// 3D: Webkit, Firefox.

// Available Transforms ------------------------------------------------------
// - Scale (2d and 3d)
// - Rotate (2d and 3d)
// - Translate (2d and 3d)
// - Skew (2d only)

// Transform Parameters ------------------------------------------------------
// - Transform Origin (2d and 3d)
// - Perspective (3d)
// - Perspective Origin (3d)
// - Transform Style (3d)
// - Backface Visibility (3d)

// Mixins --------------------------------------------------------------------
// transform-origin
//  - shortcuts:  transform-origin2d, transform-origin3d
//  - helpers:    apply-origin
// transform
//  - shortcuts:  transform2d, transform3d
//  - helpers:    simple-transform, create-transform
// perspective
//  - helpers:    perspective-origin
// transform-style
// backface-visibility
// scale
//  - shortcuts:  scaleX, scaleY, scaleZ, scale3d
// rotate
//  - shortcuts:  rotateX, rotateY, rotate3d
// translate
//  - shortcuts:  translateX, translateY, translateZ, translate3d
// skew
//  - shortcuts:  skewX, skewY

// Defaults ------------------------------------------------------------------
// @doc on

// The default x-origin for transforms
$default-origin-x    : 50%                   !default;
// The default y-origin for transforms
$default-origin-y    : 50%                   !default;
// The default z-origin for transforms
$default-origin-z    : 50%                   !default;


// The default x-multiplier for scaling
$default-scale-x     : 1.25                  !default;
// The default y-multiplier for scaling
$default-scale-y     : $default-scale-x      !default;
// The default z-multiplier for scaling
$default-scale-z     : $default-scale-x      !default;


// The default angle for rotations
$default-rotate      : 45deg                 !default;


// The default x-vector for the axis of 3d rotations
$default-vector-x    : 1                     !default;
// The default y-vector for the axis of 3d rotations
$default-vector-y    : 1                     !default;
// The default z-vector for the axis of 3d rotations
$default-vector-z    : 1                     !default;


// The default x-length for translations
$default-translate-x : 1em                   !default;
// The default y-length for translations
$default-translate-y : $default-translate-x  !default;
// The default z-length for translations
$default-translate-z : $default-translate-x  !default;


// The default x-angle for skewing
$default-skew-x      : 5deg                  !default;
// The default y-angle for skewing
$default-skew-y      : 5deg                  !default;


// **Transform-origin**
// Transform-origin sent as a complete string
//
//     @include apply-origin( origin [, 3D-only ] )
//
// where 'origin' is a space separated list containing 1-3 (x/y/z) coordinates
// in percentages, absolute (px, cm, in, em etc..) or relative
// (left, top, right, bottom, center) units
//
// @param only3d Set this to true to only apply this
// mixin where browsers have 3D support.
@mixin apply-origin($origin, $only3d) {
  @include experimental(transform-origin, $origin,
    -moz, -webkit, -o, -ms, not -khtml, official
  );
}

// Transform-origin sent as individual arguments:
//
//     @include transform-origin( [ origin-x, origin-y, origin-z, 3D-only ] )
//
// where the 3 'origin-' arguments represent x/y/z coordinates.
//
// **NOTE:** setting z coordinates triggers 3D support list, leave false for 2D support
@mixin transform-origin(
  $origin-x: $default-origin-x,
  $origin-y: $default-origin-y,
  $origin-z: false,
  $only3d:   if($origin-z, true, false)
) {
  $origin: unquote('');
  @if $origin-x or $origin-y or $origin-z {
    @if $origin-x { $origin: $origin-x; } @else { $origin: 50%; }
    @if $origin-y { $origin: $origin $origin-y; } @else { @if $origin-z { $origin: $origin 50%; }}
    @if $origin-z { $origin: $origin $origin-z; }
    @include apply-origin($origin, $only3d);
  }
}


// Transform sent as a complete string:
//
//     @include transform( transforms [, 3D-only ] )
//
// where 'transforms' is a space separated list of all the transforms to be applied.
@mixin transform(
  $transform,
  $only3d: false
) {
  @if $only3d {
    @include experimental(transform, $transform,
      -moz, -webkit, -o, -ms, not -khtml, official
    );
  } @else {
    @include experimental(transform, $transform,
      -moz, -webkit, -o, -ms, not -khtml, official
    );
  }
}

// Shortcut to target all browsers with 2D transform support
@mixin transform2d($trans) {
  @include transform($trans, false);
}

// Shortcut to target only browsers with 3D transform support
@mixin transform3d($trans) {
  @include transform($trans, true);
}

// @doc off
// 3D Parameters -------------------------------------------------------------
// @doc on

// Set the perspective of 3D transforms on the children of an element:
//
//      @include perspective( perspective )
//
// where 'perspective' is a unitless number representing the depth of the
// z-axis. The higher the perspective, the more exaggerated the foreshortening.
// values from 500 to 1000 are more-or-less "normal" - a good starting-point.
@mixin perspective($p) {
  @include experimental(perspective, $p,
    -moz, -webkit, -o, -ms, not -khtml, official
  );
}

// Set the origin position for the perspective
//
//      @include perspective-origin(origin-x [origin-y])
//
// where the two arguments represent x/y coordinates
@mixin perspective-origin($origin: 50%) {
  @include experimental(perspective-origin, $origin,
    -moz, -webkit, -o, -ms, not -khtml, official
  );
}

// Determine whether a 3D objects children also live in the given 3D space
//
//      @include transform-style( [ style ] )
//
// where `style` can be either `flat` or `preserve-3d`.
// Browsers default to `flat`, mixin defaults to `preserve-3d`.
@mixin transform-style($style: preserve-3d) {
  @include experimental(transform-style, $style,
    -moz, -webkit, -o, -ms, not -khtml, official
  );
}

// Determine the visibility of an element when it's back is turned
//
//     @include backface-visibility( [ visibility ] )
//
// where `visibility` can be either `visible` or `hidden`.
// Browsers default to visible, mixin defaults to hidden
@mixin backface-visibility($visibility: hidden) {
  @include experimental(backface-visibility, $visibility,
    -moz, -webkit, -o, -ms, not -khtml, official
  );
}

// @doc off
// Transform Partials --------------------------------------------------------
// These work well on their own, but they don't add to each other, they override.
// Use along with transform parameter mixins to adjust origin, perspective and style
// ---------------------------------------------------------------------------


// Scale ---------------------------------------------------------------------
// @doc on

// Scale an object along the x and y axis:
//
//      @include scale( [ scale-x, scale-y, perspective, 3D-only ] )
//
// where the 'scale-' arguments are unitless multipliers of the x and y dimensions
// and perspective, which works the same as the stand-alone perspective property/mixin
// but applies to the individual element (multiplied with any parent perspective)
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin scale(
  $scale-x:     $default-scale-x,
  $scale-y:     $scale-x,
  $perspective: false,
  $only3d:      false
) {
  $trans: scale($scale-x, $scale-y);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// Scale an object along the x axis
// @include scaleX( [ scale-x, perspective, 3D-only ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin scaleX(
  $scale:       $default-scale-x,
  $perspective: false,
  $only3d:      false
) {
  $trans: scaleX($scale);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// Scale an object along the y axis
// @include scaleY( [ scale-y, perspective, 3D-only ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin scaleY(
  $scale:       $default-scale-y,
  $perspective: false,
  $only3d:      false
) {
  $trans: scaleY($scale);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// Scale an object along the z axis
// @include scaleZ( [ scale-z, perspective ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin scaleZ(
  $scale: $default-scale-z,
  $perspective: false
) {
  $trans: scaleZ($scale);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// Scale and object along all three axis
// @include scale3d( [ scale-x, scale-y, scale-z, perspective ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin scale3d(
  $scale-x:     $default-scale-x,
  $scale-y:     $default-scale-y,
  $scale-z:     $default-scale-z,
  $perspective: false
) {
  $trans: scale3d($scale-x, $scale-y, $scale-z);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// @doc off
// Rotate --------------------------------------------------------------------
// @doc on

// Rotate an object around the z axis  (2D)
// @include rotate( [ rotation, perspective, 3D-only ] )
// where 'rotation' is an angle set in degrees (deg) or radian (rad) units
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin rotate(
  $rotate:      $default-rotate,
  $perspective: false,
  $only3d:      false
) {
  $trans: rotate($rotate);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// A longcut for 'rotate' in case you forget that 'z' is implied
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin rotateZ(
  $rotate:      $default-rotate,
  $perspective: false,
  $only3d:      false
) {
  @include rotate($rotate, $perspective, $only3d);
}

// Rotate an object around the x axis (3D)
// @include rotateX( [ rotation, perspective ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin rotateX(
  $rotate: $default-rotate,
  $perspective: false
) {
  $trans: rotateX($rotate);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// Rotate an object around the y axis (3D)
// @include rotate( [ rotation, perspective ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin rotateY(
  $rotate: $default-rotate,
  $perspective: false
) {
  $trans: rotateY($rotate);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// Rotate an object around an arbitrary axis (3D)
// @include rotate( [ vector-x, vector-y, vector-z, rotation, perspective ] )
// where the 'vector-' arguments accept unitless numbers.
// These numbers are not important on their own, but in relation to one another
// creating an axis from your transform-origin, along the axis of Xx = Yy = Zz.
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin rotate3d(
  $vector-x: $default-vector-x,
  $vector-y: $default-vector-y,
  $vector-z: $default-vector-z,
  $rotate: $default-rotate,
  $perspective: false
) {
  $trans: rotate3d($vector-x, $vector-y, $vector-z, $rotate);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// @doc off
// Translate -----------------------------------------------------------------
// @doc on

// Move an object along the x or y axis (2D)
// @include translate( [ translate-x, translate-y, perspective, 3D-only ] )
// where the 'translate-' arguments accept any distance in percentages or absolute (px, cm, in, em etc..) units.
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin translate(
  $translate-x: $default-translate-x,
  $translate-y: $default-translate-y,
  $perspective: false,
  $only3d:      false
) {
  $trans: translate($translate-x, $translate-y);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// Move an object along the x axis (2D)
// @include translate( [ translate-x, perspective, 3D-only ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin translateX(
  $trans-x:     $default-translate-x,
  $perspective: false,
  $only3d:      false
) {
  $trans: translateX($trans-x);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// Move an object along the y axis (2D)
// @include translate( [ translate-y, perspective, 3D-only ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin translateY(
  $trans-y:     $default-translate-y,
  $perspective: false,
  $only3d:      false
) {
  $trans: translateY($trans-y);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform($trans, $only3d);
}

// Move an object along the z axis (3D)
// @include translate( [ translate-z, perspective ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin translateZ(
  $trans-z:     $default-translate-z,
  $perspective: false
) {
  $trans: translateZ($trans-z);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// Move an object along the x, y and z axis (3D)
// @include translate( [ translate-x, translate-y, translate-z, perspective ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin translate3d(
  $translate-x: $default-translate-x,
  $translate-y: $default-translate-y,
  $translate-z: $default-translate-z,
  $perspective: false
) {
  $trans: translate3d($translate-x, $translate-y, $translate-z);
  @if $perspective { $trans: perspective($perspective) $trans; }
  @include transform3d($trans);
}

// @doc off
// Skew ----------------------------------------------------------------------
// @doc on

// Skew an element:
//
//     @include skew( [ skew-x, skew-y, 3D-only ] )
//
// where the 'skew-' arguments accept css angles in degrees (deg) or radian (rad) units.
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin skew(
  $skew-x: $default-skew-x,
  $skew-y: $default-skew-y,
  $only3d: false
) {
  $trans: skew($skew-x, $skew-y);
  @include transform($trans, $only3d);
}

// Skew an element along the x axiz
//
//     @include skew( [ skew-x, 3D-only ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin skewX(
  $skew-x: $default-skew-x,
  $only3d: false
) {
  $trans: skewX($skew-x);
  @include transform($trans, $only3d);
}

// Skew an element along the y axis
//
//     @include skew( [ skew-y, 3D-only ] )
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin skewY(
  $skew-y: $default-skew-y,
  $only3d: false
) {
  $trans: skewY($skew-y);
  @include transform($trans, $only3d);
}


// Full transform mixins
// For settings any combination of transforms as arguments
// These are complex and not highly recommended for daily use. They are mainly
// here for backward-compatibility purposes.
//
// * they include origin adjustments
// * scale takes a multiplier (unitless), rotate and skew take degrees (deg)
//
// **Note** This mixin cannot be combined with other transform mixins.
@mixin create-transform(
  $perspective: false,
  $scale-x:     false,
  $scale-y:     false,
  $scale-z:     false,
  $rotate-x:    false,
  $rotate-y:    false,
  $rotate-z:    false,
  $rotate3d:    false,
  $trans-x:     false,
  $trans-y:     false,
  $trans-z:     false,
  $skew-x:      false,
  $skew-y:      false,
  $origin-x:    false,
  $origin-y:    false,
  $origin-z:    false,
  $only3d:      false
) {
  $trans: unquote("");

  // perspective
  @if $perspective { $trans: perspective($perspective) ; }

  // scale
  @if $scale-x and $scale-y {
    @if $scale-z { $trans: $trans scale3d($scale-x, $scale-y, $scale-z); }
    @else { $trans: $trans scale($scale-x, $scale-y); }
  } @else {
    @if $scale-x { $trans: $trans scaleX($scale-x); }
    @if $scale-y { $trans: $trans scaleY($scale-y); }
    @if $scale-z { $trans: $trans scaleZ($scale-z); }
  }

  // rotate
  @if $rotate-x { $trans: $trans rotateX($rotate-x); }
  @if $rotate-y { $trans: $trans rotateY($rotate-y); }
  @if $rotate-z { $trans: $trans rotateZ($rotate-z); }
  @if $rotate3d { $trans: $trans rotate3d($rotate3d); }

  // translate
  @if $trans-x and $trans-y {
    @if $trans-z { $trans: $trans translate3d($trans-x, $trans-y, $trans-z); }
    @else { $trans: $trans translate($trans-x, $trans-y); }
  } @else {
    @if $trans-x { $trans: $trans translateX($trans-x); }
    @if $trans-y { $trans: $trans translateY($trans-y); }
    @if $trans-z { $trans: $trans translateZ($trans-z); }
  }

  // skew
  @if $skew-x and $skew-y { $trans: $trans skew($skew-x, $skew-y); }
  @else {
    @if $skew-x { $trans: $trans skewX($skew-x); }
    @if $skew-y { $trans: $trans skewY($skew-y); }
  }

  // apply it!
  @include transform($trans, $only3d);
  @include transform-origin($origin-x, $origin-y, $origin-z, $only3d);
}


// A simplified set of options
// backwards-compatible with the previous version of the 'transform' mixin
@mixin simple-transform(
  $scale:    false,
  $rotate:   false,
  $trans-x:  false,
  $trans-y:  false,
  $skew-x:   false,
  $skew-y:   false,
  $origin-x: false,
  $origin-y: false
) {
  @include create-transform(
    false,
    $scale, $scale, false,
    false, false, $rotate, false,
    $trans-x, $trans-y, false,
    $skew-x, $skew-y,
    $origin-x, $origin-y, false,
    false
  );
}

// Change the box model for Mozilla, Webkit, IE8 and the future
//
// @param $bs
//   [ content-box | border-box ]

@mixin box-sizing($bs) {
  $bs: unquote($bs);
  @include experimental(box-sizing, $bs,
    -moz, -webkit, not -o, not -ms, not -khtml, official
  );
}
// The default color for box shadows
$default-box-shadow-color: #333333 !default;

// The default horizontal offset. Positive is to the right.
$default-box-shadow-h-offset: 0px !default;

// The default vertical offset. Positive is down.
$default-box-shadow-v-offset: 0px !default;

// The default blur length.
$default-box-shadow-blur: 5px !default;

// The default spread length.
$default-box-shadow-spread : false !default;

// The default shadow inset: inset or false (for standard shadow).
$default-box-shadow-inset : false !default;

// Provides cross-browser for Webkit, Gecko, and CSS3 box shadows when one or more box
// shadows are needed.
// Each shadow argument should adhere to the standard css3 syntax for the
// box-shadow property.
@mixin box-shadow(
  $shadow-1 : default,
  $shadow-2 : false,
  $shadow-3 : false,
  $shadow-4 : false,
  $shadow-5 : false,
  $shadow-6 : false,
  $shadow-7 : false,
  $shadow-8 : false,
  $shadow-9 : false,
  $shadow-10: false
) {
  @if $shadow-1 == default {
    $shadow-1 : -compass-space-list((if($default-box-shadow-inset, inset, false), $default-box-shadow-h-offset, $default-box-shadow-v-offset, $default-box-shadow-blur, $default-box-shadow-spread, $default-box-shadow-color));
  }
  $shadow : ($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10);
  @include experimental(box-shadow, $shadow,
    -moz, -webkit, not -o, not -ms, not -khtml, official
  );
}

// Provides a single cross-browser CSS box shadow for Webkit, Gecko, and CSS3.
// Includes default arguments for color, horizontal offset, vertical offset, blur length, spread length, and inset.
@mixin single-box-shadow(
  $color  : $default-box-shadow-color,
  $hoff   : $default-box-shadow-h-offset,
  $voff   : $default-box-shadow-v-offset,
  $blur   : $default-box-shadow-blur,
  $spread : $default-box-shadow-spread,
  $inset  : $default-box-shadow-inset
) {
  @if not ($inset == true or $inset == false or $inset == inset) {
    @warn "$inset expected to be true or the inset keyword. Got #{$inset} instead. Using: inset";
  }

  @if $color == none {
    @include box-shadow(none);
  } @else {
    $full   : $hoff $voff;
    @if $blur   { $full: $full $blur;   }
    @if $spread { $full: $full $spread; }
    @if $color  { $full: $full $color;  }
    @if $inset  { $full: inset $full;   }
    @include box-shadow($full);
  }
}