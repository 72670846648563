input[type=text], input[type=search], input[type=email], input[type=password], textarea{
    width: 100%;
    display: block;
    background: none;
    border: 1px solid #e5e5e5;
    @include play;
    @include transition(border-color 0.2s);
    font-size: 14px;
    color: #333;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 11px 12px 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border: 1px solid $cYellow;
    }
    &::-webkit-input-placeholder {
        @include play;
        letter-spacing: 4px;
        color: #ccc;
    }
    &:-moz-placeholder {
        @include play;
        letter-spacing: 4px;
        color: #ccc;
    }
    &:-ms-input-placeholder {
        color: #ccc;
        letter-spacing: 4px;
        @include play;
    }

}

textarea {
    resize: none;
    border: 1px solid #e5e5e5;
    text-align: left;
    padding-left: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include transition(border-color 0.2s);
    height: 150px;
    &:focus {
        border:1px solid $cYellow;
    }
}

button, input[type=submit], .btn {
    border:1px solid #999;
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include play;
    @include font(#333, 12px, 1);
    @include transition(color 0.2s, background-color 0.2s, border-color 0.2s);
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    background: transparent;
    -webkit-appearance: none;
    &:focus {
        outline:none;
        border-color: $cYellow;
    }
    &:hover {
        text-decoration: none;
        background: #fff;
        color: $cYellow;
        border-color: $cYellow;
        cursor: pointer;
    }

    &:disabled, &.disabled {
        border-color:#999999;
        color:#999999;
        &:hover {
            cursor: default;
        }
    }
    &.btn-secondary {
        padding: 0;
        display: inline-block;
        background: none;
        border:none;
        border-bottom: 1px solid #999;
        padding-bottom: 5px;
        &:hover {
            border-bottom-color: $cYellow;
        }
    }

}


input[type=checkbox], input[type=radio] {
    display:none;
}

input[type=checkbox] {
    +label.cb-label {
        position: relative;
        display: inline-block;
        vertical-align:middle;
        cursor: pointer;
        padding-left: 0;
        font-size: 14px;
        +input {
            +label.cb-label {
                margin-left: 30px;
            }
        }

        &:before {
            content:'';
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border: 1px solid #e5e5e5;
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            @include transition(border-color 0.2s);
        }

        &:after {
            content:'g';
            position: absolute;
            font-size: 18px;
            color: $cYellow;
            left: 6px;
            top: 5px;
            font-family: "nmk" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            @include opacity(0);
            visibility: hidden;
            @include transform(translateY(-20px));
            @include transition(opacity 0.2s, visibility 0.2s, transform 0.2s);
        }

        &:hover {
            &:before {
                border-color: $cYellow;
            }
        }
    }

    &.checked {
        +label.cb-label {
            &:before {
                border-color: #333;
            }
            &:after {
                @include opacity(1);
                visibility: visible;
                @include transform(translateY(0));
            }
        }
    }
    &:checked {
        +label.cb-label {
            &:before {
                border-color: #333;
            }
            &:after {
                @include opacity(1);
                visibility: visible;
                @include transform(translateY(0));
            }
        }
    }

    &:disabled {
        +label.cb-label {
            color: #808080;
        }
        &:hover {
            +label.cb-label {
                &:before {
                    border-color: #e5e5e5;
                }
            }
        }
    }
    &.disabled {
        +label.cb-label {
            color: #808080;
        }
        &:hover {
            +label.cb-label {
                &:before {
                    border-color: #e5e5e5;
                }
            }
        }
    }
}

input[type=radio] {
    +label.cb-label {
        position: relative;
        display: inline-block;
        vertical-align:middle;
        cursor: pointer;
        padding-left: 0;
        font-size: 14px;
        margin-bottom: 0;
        +input {
            +label.cb-label {
                margin-left: 30px;
            }
        }

        &:before {
            content:'';
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            border: 1px solid #e5e5e5;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            @include transition(border-color 0.2s);
        }

        &:after {
            content:'';
            position: absolute;
            width: 6px;
            height: 6px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            background-color: $cYellow;
            left: 7px;
            top: 7px;
            @include opacity(0);
            visibility: hidden;
            @include transform(scale(1.5));
            @include transition(opacity 0.2s, visibility 0.2s, transform 0.2s);
        }

        &:hover {
            &:before {
                border-color: $cYellow;
            }
        }

    }

    &.checked {
        +label.cb-label {
            &:before {
                border-color: #333;
            }
            &:after {
                @include opacity(1);
                visibility: visible;
                @include transform(scale(1));
            }
        }
    }
    &:checked {
        +label.cb-label {
            &:before {
                border-color: #333;
            }
            &:after {
                @include opacity(1);
                visibility: visible;
                @include transform(scale(1));
            }
        }
    }
    &:disabled {
        +label.cb-label {
            color: #808080;
        }
        &:hover {
            +label.cb-label {
                &:before {
                    border-color: #e5e5e5;
                }
            }
        }
    }
    &.disabled {
        +label.cb-label {
            color: #808080;
        }
        &:hover {
            +label.cb-label {
                &:before {
                    border-color: #e5e5e5;
                }
            }
        }
    }
}

.common-form-cnt {
    fieldset {
        margin-bottom: 15px;

        label{
            @include play;
            padding-left: 12px;
            @include font(#666666 ,12px, 1);
            margin-bottom: 8px;
            display: block;
            &.add-file-input {
                padding-left: 0;
                margin-bottom: 0;
                display: inline-block;
            }
        }
    }

    fieldset.input-cnt {
        position: relative;
        margin-bottom: 15px;
        @include transition(border-color 0.2s);

        &.parsley-error {
            border:none;
            &:before, &:after {
                display: none;
            }
            input[type=text], input[type=email], input[type=password], textarea {
                border:1px solid #f63131;
                color: #f63131;
            }
            &.focus {
                border: none;
            }
        }
    }
}

.form-note {
    @include ptsans;
    @include font(#333333, 13px, 1);
    margin-bottom: 20px;
}

.form-input-inner {
    position: absolute;
    right: 0;
    top: 22px;
    width: 50px;
    height: 40px;
    @include play;
    @include font(#666666, 11px, 40px);
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: left;
    +input[type=text] {
        padding-right: 50px;
    }
}

.inline-submit-cnt {
    margin-top: 25px;
    .btn {
        margin-bottom: 0;
        display: inline-block;
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
        +.btn {
            margin-left: 15px;
        }
    }
}